import React, { useRef, useState } from "react";

import AppDownload from "./AppDownload";
import {SocialIcons} from './Header'

export default () => {
    const [email, setEmail] = useState('')
    const [subscribed, setSubscribed] = useState(false)
    const [message, setMessage] = useState('')
    const emailInput = useRef()

    function subscribe(e){
        // e.target.preventDefault()
        if(email){
            setEmail('')
            setSubscribed(true)
            setMessage('Email address subscribed successfully')
            emailInput.current.value = ''
        } else {
            setSubscribed(false)
            setMessage('Please input a valid email address')
            alert('Please input a valid email address')
        }
    }

    function toggleSubscription(){
        setSubscribed(!subscribed)
    }

    return (
        <footer className="mt-4" id="footer">
            
            <div className="container p-4 mx-auto mt-4">
                <div className="grid grid-cols-1 md:grid-cols-2 justify-evenly gap-10 pt-10" >
                    <div>
                        <div className="mx-auto md:mx-0">
                            <h3 className="text-2xl font-bold text-white">Subscribe</h3>
                            <p className="mt-2 text-sm text-white/80" style={{ maxWidth: '70%' }}>Join our mailing list to receive some of our best posts today.</p>
                            <form className="my-4 w-full flex">
                                <input ref={emailInput} className="rounded-l-lg md:p-4 p-2 border-t mr-0 border-b border-l text-gray-800 border-gray-200 bg-white" placeholder="your@mail.com" onChange={(e) => setEmail(e.target.value)} />
                                <button type="button" className="md:px-8 px-4 rounded-r-lg bg-theme-blue font-bold uppercase text-gray-200" onClick={(e)=>subscribe(e)}>Subscribe</button>
                            </form>
                        </div>
                    </div>

                    <div>
                        <AppDownload />
                    </div>

                </div>
            </div>
            <DetailFooter />
            <Modal subscribed={subscribed} toggleSubscribe={toggleSubscription}/>
        </footer>
    )

}

function Modal({subscribed, toggleSubscribe}){
    return (
        <div id="popup-modal" tabIndex="-1" className={`fixed top-0 left-0 right-0 z-50  overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full ${subscribed?'flex':'hidden'}`}>
    <div className="relative w-full h-full max-w-md p-4 md:h-auto">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <button type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center " data-modal-toggle="popup-modal" onClick={()=>toggleSubscribe()}>
                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>  
            </button>
            <div className="p-6 text-center">
                <svg className="mx-auto mb-4 text-gray-400 w-14 h-14 dark:text-gray-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Email address subscribed successfully</h3>
                <button data-modal-toggle="popup-modal" type="button" className="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2" onClick={()=>toggleSubscribe()}>
                    CLOSE
                </button>
                {/* <button data-modal-toggle="popup-modal" type="button" className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">No, cancel</button> */}
            </div>
        </div>
    </div>
</div>
    )
}

function DetailFooter() {

    return ( 
    <div className="container p-4 mx-auto mt-6">
    <div className="lg:flex">
        <div className="w-full lg:w-2/5">
            <div className="">
                <div>
                    <a href="#" className="text-xl font-bold text-white">OPTION TRADING ONLY</a>
                </div>
                
                <p className="max-w-md mt-2 text-gray-500 dark:text-gray-400 pr-2 md:text-left sm:text-justify">Option Trading Only by Prateek Varshney, 7+ years of experience in stock market.
                    Here to give a better perspective to retail traders. 
                    <br/>
                    <br/>
                    • Don't over complicate the chart
                    <br/>
                    • Trade for either Target or SL
                    <br/>
                    <br/>
                    <b>#nifty</b> <b>#banknifty</b> <b>#priceaction</b>
                </p>
            </div>
        </div>

        <div className="mt-6 lg:mt-0 lg:flex-1">
            <div className="grid grid-cols-1 md:grid-cols-3 md:gap-6">
                <div>
                    <h3 className="text-white uppercase">Important Links</h3>
                    <a href="/?#features" className="block mt-2 text-md text-gray-400 hover:text-blue-400"> Features </a>
                    <a href="/?#courses" className="block mt-2 text-md text-gray-400 hover:text-blue-400"> Courses </a>
                    <a href="/about-us" className="block mt-2 text-md text-gray-400 hover:underline">About Us</a>
                    <a href="/contact-us" className="block mt-2 text-md text-gray-400 hover:underline">Contact Us</a>
                    <a href="/?#wju" className="block mt-2 text-md text-gray-400 hover:text-blue-400"> Why Join Us </a>
                </div>
                <div>
                    <h3 className="text-white uppercase hidden md:flex">&nbsp;</h3>
                    <a href="https://bit.ly/3X7dYkZ" target="_blank" className="block mt-2 text-md text-gray-400 hover:underline"><b>Open Demat</b></a>
                    <a href="/terms-and-conditions" className="block mt-2 text-md text-gray-400 hover:underline">Terms and Conditions</a>
                    <a href="/refund-policy" className="block mt-2 text-md text-gray-400 hover:underline">Refund Policy</a>
                    <a href="/privacy-policy" className="block mt-2 text-md text-gray-400 hover:underline">Privacy Policy</a>
                    {/* <a href="https://zerodha.com/open-account?c=CL0086" target="_blank" className="block mt-2 text-md text-gray-400 hover:underline">Open Zerodha Demat Account</a> */}
                </div>
                <div className="mt-2 md:mt-0">
                    <h3 className="text-white uppercasee">CONTACT</h3>
                    <a target={"_blank"}  className="block mt-2 text-md text-gray-400 hover:underline" style={{whiteSpace: 'nowrap'}} href="mailto:optiontradingonly@gmail.com">Email: optiontradingonly@gmail.com</a>
                    {/* <a target={"_blank"}  className="block mt-2 text-md text-gray-400 hover:underline" style={{whiteSpace: 'nowrap'}} href="mailto:prateekvarshney1224@gmail.com">Email2: prateekvarshney1224@gmail.com</a> */}
                    <a target={"_blank"}  className="block mt-2 text-md text-gray-400 hover:underline" style={{whiteSpace: 'nowrap'}} href="https://t.me/optiontradingoriginal">Telegram: optiontradingoriginal</a>
                    <a target={"_blank"}  className="block mt-2 text-md text-gray-400 hover:underline" style={{whiteSpace: 'nowrap'}} href="https://www.instagram.com/optiontradingonly">Instagram: optiontradingonly</a>
                    
                    <div className="flex mt-4 -mx-2">
                        <SocialIcons></SocialIcons>
                    </div>
                    <div className="block mt-4 -mx-2 text-gray-400">
                        {/*<p style={{fontSize: 14}}>VARSHNEY ENTERPRISE</p>*/}
                        {/* <p style={{fontSize: 10}}><i>Registered Address:</i> <b>F-1005, Ajnara Integrity, Rajnagar Extension, Ghaziabad</b></p> */}
                        {/*<p style={{fontSize: 12, marginTop: 5}}><i>Corporate Address:</i> <b>M3M Cosmopolitan, Sector 66, Gurugram, Haryana 122001</b></p>*/}
                    </div>
                    
                </div>

            </div>
        </div>
    </div>

    <hr className="h-px my-6 bg-gray-300 border-none dark:bg-gray-700" />

    <div>
        <p className="text-center text-white text-xs">© 2022 - All rights reserved | Option&nbsp;Trading&nbsp;Only | <i>#&nbsp;OK&nbsp;BYE</i> </p>
    </div>
</div>)
}