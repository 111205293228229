import * as firebaseui from "firebaseui";
import 'firebaseui/dist/firebaseui.css'
import React, { useEffect, useState } from "react";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import firebaseApp from '../firebase'
const auth = getAuth(firebaseApp);
const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);
  
export default (Component) => ({...props}) => {

    const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.

    // Listen to the Firebase Auth state and set the local state.
    useEffect(() => {
        auth.onAuthStateChanged(user => {
            setIsSignedIn(!!user);
            if(!user){
                ui.start('#container', {
                    signInFlow: 'popup',
                    signInOptions: [
                        GoogleAuthProvider.PROVIDER_ID
                    ]
                })
            } else {
                
            }
          });
    }, []);

    if (!isSignedIn) {
      return (
        <div>
          <h1>My App</h1>
          <p>Please sign-in:</p>
          <div id="container"></div>
        </div>
      );
    }

    return (<Component {...props}/>)
}
