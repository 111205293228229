export default () => {
    return (
        <div className="mt-4 pt-4">
            <hr></hr>
            <h3 className="my-4 pt-4">Frequently Asked Questions</h3>

            <h4 className="my-4 pt-4">Q1- Is this course suitable for Beginners?</h4>
            <span className="text-justify">Yes, it is Beginner-Intermediate friendly<br/>
            Check the course syllabus above and then decide accordingly.</span>

            <h4 className="my-4 pt-4">Q2- Fund requirement after this course?</h4>
            <span className="text-justify">This course teaches you how to read the charts, how and where you use these skills will answer your question.
            For example: It takes zero fund to practice your skills on charts,
            for intraday stocks you can start with 500₹ or even lower and for Option buying, 10-15k.</span>

            <h4 className="my-4 pt-4">Q3- Will I be a Crorepati after this course?</h4>
            <span className="text-justify">No, it is not a GET-RICH-QUICK Scheme.
            You are here to learn to read the charts, how you use your skills afterwards will depend entirely on you and your decision making ability.</span>
        </div>
    )
}