
import React, { useState } from "react";
import logo from './../assets/logo.png'
import logoWebp from './../assets/logo.webp'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faTelegram, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons'
import ImgWithFallback from "./ImageWithFallback";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

export default () => {
  
  const [openMenu, setOpenMenu] = useState(false);
  const location = useLocation()

  useEffect(() => {
    if(location.hash) {
      setTimeout(() => {
        try {
          document.querySelector(`[href="${location.hash}"]`).click()
        } catch (error) {
          console.log(error, `[href="${location.hash}"]`)
        }
      }, 200);
    }
  },[])

  return (
<>
<div className="relative bg-dark" id="header1">
  <div className="max-w-7xl mx-auto px-4 sm:px-6">
    <div className="flex sm-flex-col md:justify-between items-center py-1 md:justify-start md:space-x-10">
       <nav className="flex sm-flex-col md:space-x-10">
            <a href="mailto:optiontradingonly@gmail.com" className="hidden md:flex text-base font-small text-white hover:text-blue-400"> optiontradingonly@gmail.com </a>
            <a href="https://t.me/optiontradingoriginal" target={"_blank"} className="hidden text-base font-small text-gray-500 hover:text-blue-400"> Join our Telegram Channel</a>
       </nav>
       <div className="md:flex items-center justify-end md:flex-1 lg:w-0">
          <SocialIcons></SocialIcons>
        </div>
       </div>
    </div>
  </div>

    {/* END OF TOP */}

    <div className="relative dark:bg-dark" id="header2">
  <div className="max-w-7xl mx-auto px-4 sm:px-6">
    <div className="flex justify-between items-center border-gray-800 md:justify-start md:space-x-10" >
    {/* style={{borderBottom: '1px solid rgb(68 192 237 / 57%)'}}  */}
      {/* border-b-2  */}
      <div className="flex justify-start lg:w-0 lg:flex-1">
        <a href="/">
          {/* <span className="sr-only">Option Trading Only</span> */}
          <ImgWithFallback src={logoWebp} fallback={logo}  className="w-auto" alt="Option Trading Only" style={{height: 50}}/>
          {/* <img className="w-auto" src={logo} alt="Option Trading Only" style={{height: 50}}/> */}
          {/* <span className="text-white text-4xl" style={{ fontFamily: 'sans-serif'}}>Option Trading Only</span> */}
        </a>
      </div>
      <div className="-mr-2 -my-2 md:hidden">
        <button type="button" className="bg-dark rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" aria-expanded="false" onClick={() => setOpenMenu(!openMenu)}>
          <span className="sr-only">Open menu</span>
          <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true">
            <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        </button>
      </div>
      
      <nav className="hidden md:flex space-x-10">
        <a href="/" className="text-base font-medium text-white hover:text-blue-400"> Home </a>
        <a href="/#features" className="text-base font-medium text-white hover:text-blue-400"> Features </a>
        <a href="/#courses" className="text-base font-medium text-white hover:text-blue-400"> Courses </a>
        <a href="/#wju" className="text-base font-medium text-white hover:text-blue-400"> Why Join Us </a>
        <a href="/about-us" className="text-base font-medium text-white hover:text-blue-400"> About Us </a>
        <a href="https://bit.ly/3X7dYkZ" target="_blank" className="text-base font-medium text-white hover:text-blue-400"> Open Demat </a>
        <a href="https://www.prateekvarshney.com" target="_blank" className="text-base font-medium text-white hover:text-blue-400"> Fitness </a>
      </nav>
      {/* 
        <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
          <a href="#" className="whitespace-nowrap text-base font-medium text-white hover:text-blue-400"> Sign in </a>
          <a href="#" className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"> Sign up </a> 
        </div> 
      */}
    </div>
  </div>


  {openMenu && <div className="absolute top-0 inset-x-0 px-2 transition transform origin-top-right md:hidden" style={{ zIndex: 100 }}>
    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
      <div className="pt-5 pb-6 px-5 bg-gray-800">
        <div className="flex items-center justify-between">
          <div>
            <img className="h-8 w-auto" src={logo} alt="Option Trading Only" style={{height: 50}} />
            {/* <span className="text-white text-4xl" style={{    fontFamily: 'sans-serif'}}>Option Trading Only</span> */}
          </div>
          <div className="-mr-2">
            <button type="button" className="bg-gray-900 rounded-md p-2 inline-flex items-center justify-center text-gray-200 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" onClick={() => setOpenMenu(false)}>
              <span className="sr-only">Close menu</span>
              <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
        <div className="mt-6">
          <nav className="grid gap-y-8">
            <a href="/" className="text-base font-medium text-white hover:text-blue-400" onClick={() => setOpenMenu(false)}> Home </a>
            <a to={{pathname: '/', hash: '#features'}} href="/#features" className="text-base font-medium text-white hover:text-blue-400" onClick={() => setOpenMenu(false)}> Features </a>
            <a href="/#courses" className="text-base font-medium text-white hover:text-blue-400" onClick={() => setOpenMenu(false)}> Courses </a>
            {/* <a hhref="https://zerodha.com/open-account?c=CL0086" target="_blank" className="text-base font-medium text-white hover:text-blue-400" onClick={() => setOpenMenu(false)}>Open Zerodha Demat Account</a> */}
            <a href="/#wju" className="text-base font-medium text-white hover:text-blue-400" onClick={() => setOpenMenu(false)}> Why Join Us </a>
            <a href="/about-us" className="text-base font-medium text-white hover:text-blue-400" onClick={() => setOpenMenu(false)}> About Us </a>
            <a href="https://bit.ly/3X7dYkZ" target="_blank" className="text-base font-medium text-white hover:text-blue-400"> Open Demat </a>
            <a href="https://www.prateekvarshney.com" target="_blank" className="text-base font-medium text-white hover:text-blue-400"> Fitness </a>
        {/* 
            
            <a href="#" className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
              <svg className="flex-shrink-0 h-6 w-6 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
              </svg>
              <span className="ml-3 text-base font-medium text-gray-900"> Analytics </span>
            </a>

            <a href="#" className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
              <svg className="flex-shrink-0 h-6 w-6 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122" />
              </svg>
              <span className="ml-3 text-base font-medium text-gray-900"> Engagement </span>
            </a>

            <a href="#" className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
              <svg className="flex-shrink-0 h-6 w-6 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
              </svg>
              <span className="ml-3 text-base font-medium text-gray-900"> Security </span>
            </a>

            <a href="#" className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
              <svg className="flex-shrink-0 h-6 w-6 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
              </svg>
              <span className="ml-3 text-base font-medium text-gray-900"> Integrations </span>
            </a>

            <a href="#" className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
              <svg className="flex-shrink-0 h-6 w-6 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
              </svg>
              <span className="ml-3 text-base font-medium text-gray-900"> Automations </span>
            </a> 
            */}
          </nav>
        </div>
      </div>
      {/* <div className="py-6 px-5 space-y-6">
        <div className="grid grid-cols-2 gap-y-4 gap-x-8">
          <a href="#" className="text-base font-medium text-gray-900 hover:text-gray-700"> Pricing </a>

          <a href="#" className="text-base font-medium text-gray-900 hover:text-gray-700"> Docs </a>

          <a href="#" className="text-base font-medium text-gray-900 hover:text-gray-700"> Help Center </a>

          <a href="#" className="text-base font-medium text-gray-900 hover:text-gray-700"> Guides </a>

          <a href="#" className="text-base font-medium text-gray-900 hover:text-gray-700"> Events </a>

          <a href="#" className="text-base font-medium text-gray-900 hover:text-gray-700"> Security </a>
        </div>
        <div>
          <a href="#" className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"> Sign up </a>
          <p className="mt-6 text-center text-base font-medium text-gray-500">
            Existing customer?
            <a href="#" className="text-indigo-600 hover:text-indigo-500"> Sign in </a>
          </p>
        </div>
      </div> */}
    </div>
  </div>}

  {/* {
            <a className={("mt-2")+" text-xl text-center block mt-6"} style={{color: '#3fbfef'}} href="https://t.me/optiontradingoriginal" target={"_blank"} aria-label="Telegram"><FontAwesomeIcon icon={faTelegram}  /> Join our Free Telegram Group (For realtime updates during market hours)</a>
        } */}
</div>
</>

    )
}

function SocialIcons({size = 18}) {
  return (
    <>
      <a href="https://t.me/optiontradingoriginal" aria-label="Telegram" target={"_blank"} style={{fontSize: size}} className="px-4 py-2 font-semibold text-white inline-flex items-center space-x-2 rounded">
            <FontAwesomeIcon icon={faTelegram} />
          </a>
          <a  href="https://www.instagram.com/optiontradingonly" aria-label="Instagram" style={{fontSize: size}} target={"_blank"} className="px-4 py-2 font-semibold text-white inline-flex items-center space-x-2 rounded">
            <FontAwesomeIcon icon={faInstagram}  />
          </a>
          <a href="https://www.youtube.com/c/OptionTradingOnly" aria-label="Youtube" style={{fontSize: size}} target={"_blank"} className="px-4 py-2 font-semibold text-white inline-flex items-center space-x-2 rounded">
            <FontAwesomeIcon icon={faYoutube}  />
          </a>
      </>
  )
}

export {SocialIcons}