import firebaseApp from '../firebase'
import { getAuth } from "firebase/auth";
import { collection, getDoc, getDocs, getFirestore, query, where } from "firebase/firestore";
import Constant from './Constant';
const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp)

class User{
    static async fetchList(nextToken = null){
        const token = await auth.currentUser.getIdToken(true)
        // console.log(token, auth.currentUser)
        return fetch(`${process.env.REACT_APP_ADMIN_URL}/admin/users?${nextToken?`nextToken=${nextToken}`:''}`, {
            headers: {
                ftoken: token
            }
        })
    }

    static async searchByEmail(email){
        const token = await auth.currentUser.getIdToken(true)
        // console.log(token, auth.currentUser)
        return fetch(`${process.env.REACT_APP_ADMIN_URL}/admin/user-by-email?email=${email}`, {
            headers: {
                ftoken: token
            }
        })
    }

    static async fetchOrders({uid, offset, perPage, status}){
        const token = await auth.currentUser.getIdToken(true)
        // console.log(token, auth.currentUser)
        let query = {}
        if(uid) query.uid = uid
        if(offset) query.offset = offset
        if(perPage) query.perPage = perPage
        if(status) query.status = status

        return fetch(`${process.env.REACT_APP_ADMIN_URL}/admin/orders?${(new URLSearchParams(query)).toString()}`, {
            headers: { ftoken: token }
        })
        // return getDocs(collection(db, "optiontradingonly", "orders", uid))
    }

    // static async markOrderPaid(uid, itemId, orderId){
    //     const token = await auth.currentUser.getIdToken(true)
    //     return fetch(`${process.env.REACT_APP_ADMIN_URL}/admin/orders/mark-paid`, {
    //         method: 'POST',
    //         headers: { ftoken: token , 'Content-Type': 'application/json'},
    //         body: JSON.stringify({
    //             uid, itemId, orderId
    //         })
    //     })
    // }

    static async markAdmin(uid, password, revoke = false){
        const token = await auth.currentUser.getIdToken(true)
        return fetch(`${process.env.REACT_APP_ADMIN_URL}/admin/mark-admin`, {
            method: 'POST',
            headers: { ftoken: token , 'Content-Type': 'application/json'},
            body: JSON.stringify({
                uid, password, revoke
            })
        })
    }

    static async listAdmin(){
        return getDocs(collection(db, Constant.BASE_COLLECTION, "admins", "data"))
    }

    static async adminByEmail(email){
        return getDocs( query( collection(db, Constant.BASE_COLLECTION, "admins", "data"), where('email', '==', email)))
    }

    static async markOrderPaid(uid, itemId, receiptId, vApi){
        
        const token = await auth.currentUser.getIdToken(true)
        // console.log(token, auth.currentUser)
        // const itemId = req.query.itemId
        // const receiptId = req.query.receiptId
        // const uid = req.query.uid // from post body not auth user
        // const vApi = req.query.vApi // from post body not auth user
        return fetch(`${process.env.REACT_APP_ADMIN_URL}/admin/set-paid?itemId=${itemId}&receiptId=${receiptId}&uid=${uid}&vApi=${vApi}`, {
            headers: {
                ftoken: token
            }
        })
    }
}

export default User