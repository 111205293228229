import { useEffect, useState } from "react"
import fetchCustomDate from "../utils/fetchCustomDate"
import { normalDateSetup } from "../utils/getNewDate"

function DateElement() {

    const [dates, setDates] = useState([new Date(), new Date()])
    const [customDate, setCustomDate] = useState(null)
    const [loadingDate, setLoadingDate] = useState(true)

    useEffect(() => {
        execute()
    }, [])
    
    async function execute() {
        setLoadingDate(true)
        let resp = await fetchCustomDate()
        if(resp){
            setCustomDate(resp.date)
        } else {
            setDates(normalDateSetup())
        }
        setLoadingDate(false)
    }


    const nth = function(d) {
        if (d > 3 && d < 21) return 'th';
        switch (d % 10) {
          case 1:  return "st";
          case 2:  return "nd";
          case 3:  return "rd";
          default: return "th";
        }
      }
      
    function format(date) {
        const dateStr = date.toDateString()
        const dateStrArr = dateStr.split(' ')
        
        return [date.getDate().toString()+nth(date.getDate()), dateStrArr[1]].join('-')
    }

    if(loadingDate) return <>Loading available date...</>

    return <>{customDate ? customDate : `${format(dates[0])} and ${format(dates[1])}`}</>
}

export default DateElement