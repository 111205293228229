import React from "react";
import './../assets/breadcrumb.css'

export default ({data = []}) => {
    return (
        <ul className="mb-4">
        {data.map(v => {
            return (<li key={v.name} className="breadcrumb-item"><a href={v.href} className="text-white"> {v.name} </a></li>)
        })}
        </ul>
    )
}