import React, { useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import firebaseApp from '../firebase'
import User from "../models/User";
import { toast } from "react-toastify";
import BreadCrumb from "../components/BreadCrumb";
import moment from "moment";
const auth = getAuth(firebaseApp);

export default ({}) => {

    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(false)
    const [admin, setAdmin] = useState(auth.currentUser)
    const [tokens, setTokens] = useState([])
    const [nextToken, setNextToken] = useState(null)
    const [page, setPage] = useState(1)

    const [searchEmail, setSeachEmail] = useState('')

    let perPage = 10

    useEffect(() => {
        loadUsers()
    }, [])

    const loadUsers = (_nextToken) => {
        setLoading(true)
        User.listAdmin().then(resp => {
            return resp.docs.map(v => v.data()).map(v => { return {...v, ts:  moment(v.ts.seconds*1000).format('hh:mm A Do MMM Y')} })
        })
        .then((result) => {
            setUsers(result)
        })
        .catch(err => {
            toast(`Error: ${err.message}`)
        })
        .finally(() => setLoading(false))
    }

    if(loading) {
        return  <div className="max-w-7xl mx-auto px-4 mt-4 text-center">
            <div role="status" className="mt-4">
                <svg className="inline mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                </svg>
                <span className="sr-only">Loading...</span>
            </div>
            <h2 className="text-white mt-4">Loading...</h2>
        </div>
    }
    const previousPage = function(){
        let currentIndex = tokens.indexOf(nextToken) 
        let _nextToken = currentIndex > 1 ? tokens[currentIndex - 2] : null
        loadUsers(_nextToken || null)
    }

    function searchByEmail() {
        if(ValidateEmail(searchEmail)) {
            setLoading(true)
            User.adminByEmail(searchEmail).then(resp => {
                return resp.docs.map(v => v.data()).map(v => { return {...v, ts:  moment(v.ts.seconds).format('hh:mm A Do MMM Y')} })
            })
            .then((result) => {
                console.log(result)
                setUsers(result)
            })
            .catch(err => {
                console.log(err)
                setUsers([])
            })
            .finally(() => {
                setTokens([])
                setPage(1)
                setLoading(false)
            })
        }
    }

    function ValidateEmail(mail) 
    {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
        {
            return (true)
        }
        alert("Invalid email address!")
        return (false)
    }

    function revokeAdmin(uid, name) {
        let password = prompt(`Enter password to revoke admin privilege from ${name}..`)
        if(!!password){
            User.markAdmin(uid, password, true)
            .then((resp) => {
                alert(`${name} revoked admin privilege.`)
                loadUsers()
            })
            .catch(err => {
                alert(`Error: ${err.message}`)
            })
        }
    }

    return (
        <div className="max-w-7xl mx-auto px-4 mt-4">
            <BreadCrumb data={[{href: '/admin/home', name: 'Admin Home'}, {href: '#', name: 'Admins'}]}/>

            <h4 className="text-white mt-4 mb-4">Admin List</h4>
            <div className="filters mb-2 mt-2">
                <input type={'text'} placeholder={'Search by email'} className="p-2" style={{minWidth: 250}} defaultValue={searchEmail} onChange={(v) => setSeachEmail(v.target.value)}/>
                <button className="text-white p-2 border border-blue-900" onClick={searchByEmail}>Search</button>
            </div>
            <table className="table-auto text-white bg-gray-900 rounded-t-xl" style={{width: '100%'}}>
                <thead className="bg-gray-800 border border-gray-700">
                    <tr>
                        <th className="text-left">SN</th>
                        <th className="text-left">Name</th>
                        <th className="text-left">Email</th>
                        <th className="text-center">Joined</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody className="border-gray-700">
                    {(users || []).map( (user, index) => <tr key={user.uid}>
                        <td>{(page-1)*perPage+(index+1)}</td>
                        <td>{user.displayName}</td>
                        <td>{user.email}</td>
                        <td className="text-center">{user.ts}
                        {/* {user.metadata.creationTime.substring(0, 16)} */}
                        </td>
                        <td className="text-center">
                            {/* <a href={`/admin/orders?uid=${user.uid}`} className="p-2 bg-gray-700 rounded mr-1">Orders</a>     */}
                            <a onClick={() => revokeAdmin(user.uid, user.displayName)} className="p-2 bg-gray-800 rounded">Revoke Admin</a>    
                        </td>
                    </tr>)}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={5} className="text-center">
                            <button disabled={page==1}  onClick={() => {previousPage()}} className={`${page==1?'bg-gray-700 border-gray-900':'bg-gray-900 border-blue-800'} rounded p-2 border  p-2 mr-2`}>&lt; Prev</button>
                            <span className="pl-2 pr-4">{page}</span>
                            <button  disabled={!!(nextToken)?'':true} onClick={() => {loadUsers(nextToken)}} className={`${!!(nextToken)?'bg-gray-900 border-blue-800':'bg-gray-700 border-gray-900'} rounded p-2 border `}>Next &gt;</button>
                        </td>
                    </tr>
                </tfoot>
                </table>

            {/* <h4 className="text-white">{JSON.stringify(tokens)}</h4>
            <h4 className="text-white">{nextToken}</h4>
            <h4 className="text-white">{tokens[tokens.length-3]}</h4> */}
        </div>)
}
