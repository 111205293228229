import React, { useState } from "react";
import backgroundNifty from './../assets/nifty-index.png'
import backgroundNifty2 from './../assets/nifty-index2.png'
import tradingPsychology from './../assets/trading-psychology.jpeg'
import advancedRiskManagement from './../assets/advanced-risk-management.jpg'
// import beginnerFree from './../assets/beginner-free.jpeg'
import beginnerFree from './../assets/free_course.png'


const backgrounds = [backgroundNifty2,beginnerFree,backgroundNifty,tradingPsychology,advancedRiskManagement]

export default () => {

    const [showDetails, setShowDetails] = useState(-1)

    const pricing = [
        {
            title: <>For BEGINNER <br/></>,
            subTitle: <>&nbsp; <br/>Start your journey today with our free course </>,
            pricing: <>FREE<small className="text-xs"></small></>,
            courseDetails: 'Teest 2',
            contents: <>
                <ul>
                    <li>Engaging lectures</li>
                    <li>Interesting examples</li>
                    <li>Must watch</li>
                    <li>&nbsp;</li>
                    <li><a className="text-sky-600 pointer-cursor flex justify-center text-center" href="https://www.youtube.com/playlist?list=PLJB-E05ZNmO1aCXD4jC_xQ4RirpEcJnf3" target={"_blank"} click="{() => setShowDetails(1)}"><svg className="text-gray-400 dark:text-gray-200" style={{ height: 24, width: 24, paddingTop: 7 }} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg> More Info</a></li>
                </ul>
                <div  className="w-full mt-10 px-6">
                    <a href="https://www.youtube.com/playlist?list=PLJB-E05ZNmO1aCXD4jC_xQ4RirpEcJnf3" target={"_blank"} className="w-full text-sky-300  block bg-gray-900 font-medium text-xl py-4 rounded-xl hover:shadow-lg transition duration-200 ease-in-out hover:bg-sky-500 hover:text-white" >Enroll Now</a>
                </div>
            </>
        },
        {
            title: <>For INTERMEDIATE<br/></>,
            subTitle: <>S&R Levels, Fibonacci Setup, M/W 3 Line Setup etc. </>,
            pricing: <>₹ 6999 <small className="text-xs"></small></>,
            courseDetails: 'Teest 2',
            contents: <>
                <ul>
                    <li>2 Interactive Live Classes </li>
                    <li>1 Month of One on One <br/>support every Saturday </li>
                    <li>&nbsp;</li>
                    <li><a className="text-sky-600 pointer-cursor flex justify-center text-center" href="/live-class-details" click="{() => setShowDetails(1)}"><svg className="text-gray-400 dark:text-gray-200" style={{ height: 24, width: 24, paddingTop: 7 }} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg> More Info</a></li>
                </ul>
                <div  className="w-full mt-10 px-6">
                    <a _href="https://rzp.io/l/optiontradingonly" _target={'_blank'} __href="https://t.me/optiontradingonlycourse" href="/live-class-details" className="w-full text-sky-300 block bg-gray-900 font-medium text-xl py-4 rounded-xl hover:shadow-lg transition duration-200 ease-in-out hover:bg-sky-500 hover:text-white" >Enroll Now</a>
                </div>
            </>
        },
        // {
        //     title: <>For ADVANCE<br/></>,
        //     subTitle: <>Advance Setups, Chindi Chori Scalping & <br/> Bonus Option Selling Strategy. </>,
        //     pricing: <>₹ 21000 <small className="text-xs"></small></>,
        //     courseDetails: 'Teest 2',
        //     contents: <>
        //         <ul>
        //             <li>2 Live Classes on Weekend</li>
        //             <li>2 Live Classes in Live Market</li>
        //             <li>2 One on One Support Session<br/>&nbsp;</li>
        //             <li></li>
        //             <li><a className="text-sky-600 pointer-cursor flex justify-center text-center" href="/advance-course-details" click="{() => setShowDetails(1)}"><svg className="text-gray-400 dark:text-gray-200" style={{ height: 24, width: 24, paddingTop: 7 }} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg> More Info</a></li>
        //         </ul>
        //         <div  className="w-full mt-10 px-6">
        //             <a href="https://rzp.io/l/optiontradingonlycourse" target={'_blank'} _href="https://t.me/optiontradingonlycourse" className="w-full text-sky-300 block bg-gray-900 font-medium text-xl py-4 rounded-xl hover:shadow-lg transition duration-200 ease-in-out hover:bg-sky-500 hover:text-white" >Enroll Now</a>
        //         </div>
        //     </>
        // },
        {
            title: <>Trading Psychology</>,
            subTitle: <>Fear, Greed, Confidence &amp;Trailing Stoploss <br/> 4 Problems 1 Solution</>,
            pricing: <>₹ 349 <small className="text-xs"></small></>,
            courseDetails: 'Lorem Ipsum donor',
            contents: <>
                <ul>
                    <li>4 Lectures</li>
                    <li>Engaging Videos</li>
                    <li>Simplify Your Trading</li>
                    <li>1 Month Access</li>
                    <li></li>
                    <li><a className="text-sky-600 pointer-cursor flex justify-center text-center" href="/psychology-details" click="{() => setShowDetails(0)}"><svg className="text-gray-400 dark:text-gray-200" style={{ height: 24, width: 24, paddingTop: 7 }} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg> More Info</a></li>
                </ul>
                <div id="choose" className="w-full mt-10 px-6">
                    <a href="https://play.google.com/store/apps/details?id=app.optiontradingonly.com" className="w-full text-sky-300 block bg-gray-900 font-medium text-xl py-4 rounded-xl hover:shadow-lg transition duration-200 ease-in-out hover:bg-sky-500 hover:text-white"
                    >Enroll Now</a>
                </div>
            </>
        },
        // {
        //     title: <>Risk Management</>,
        //     subTitle: <>Managing the risk in <br/>the safest manner possible</>,
        //     pricing: '₹ 1499',
        //     courseDetails: '2',
        //     contents: <>
        //         <ul>
        //             <li>4 Energetic Lectures</li>
        //             <li>From 1 Lot to 40 Lots</li> 
        //             <li>Covering basic to advance <br/>risk management</li>
        //             <li>1 Month Access</li>
        //             <li><a className="text-sky-600 pointer-cursor flex justify-center text-center" href="/advanced-risk-management" click="{() => setShowDetails(1)}"><svg className="text-gray-400 dark:text-gray-200" style={{ height: 24, width: 24, paddingTop: 7 }} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg> More Info</a></li>
        //         </ul>
        //         <div  className="w-full mt-10 px-6">
        //             <a href="https://play.google.com/store/apps/details?id=app.optiontradingonly.com" className="w-full text-sky-300  block bg-gray-900 font-medium text-xl py-4 rounded-xl hover:shadow-lg transition duration-200 ease-in-out hover:bg-sky-500 hover:text-white" >Enroll Now</a>
        //         </div>
        //     </>
        // },
    ]

    return (
        <div className="antialiased w-full h-full bg-black text-gray-400 font-inter pt-10 px-4" id="courses">
            <div className="container mx-auto">
            <div>
                <div id="title" className="text-center my-10">
                <h1 className="font-bold text-4xl text-white">OUR COURSES</h1>
                <p className="text-light text-gray-400 text-xl mt-6">
                The best first step for you to work on your skills.
                </p>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 justify-evenly gap-10 pt-10 mx-auto"  style={{maxWidth: 1080}} >
                {pricing.filter((v, i) => i <= 2).map((data, i) => <Pricing data={data} index={i} key={i}  />)}
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 justify-center gap-10 pt-10 mx-auto"  style={{maxWidth: 1080}} >
                {pricing.filter((v, i) => i > 2).map((data, i) => <Pricing data={data} index={i+3} key={i+2}  />)}
                </div>
            </div>
            <Modal showDetails={showDetails} showTitle={showDetails>-1?pricing[showDetails].title:''} toggleDetails={setShowDetails} content={showDetails>-1?pricing[showDetails].courseDetails:''}/>
            </div>
        </div>
    )
}

function Pricing({data, index}) {
    const animations = ['zoom-in-top', 'zoom-in-top', 'zoom-in-top', 'zoom-in-top','zoom-in-top', 'zoom-in-top', 'zoom-in-top', 'zoom-in-top','zoom-in-top', 'zoom-in-top', 'zoom-in-top', 'zoom-in-top']
    return (
        <div className="rounded-lg text-center overflow-hidden w-full transform border border-gray-700 hover:shadow-2xl hover:scale-105 transition duration-200 ease-in" data-aos={animations[index]} data-aos-duration="2000"
            style={{background: `url(${backgrounds[index]})`, backgroundColor: 'rgba(0,0,0,0.7)', backgroundBlendMode: 'overlay', backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }} >
            <div id="title" className="w-full py-5 border-b border-gray-800">
                <h2 className="font-normal font-bold text-sky-300 text-xl mt-2">{data.title}</h2>
                <h3 className="font-bold text-3xl text-white mt-4"> {data.pricing} </h3>
            </div>
            <div id="content" className="">
            <div id="icon" className="my-5">
                <p className="text-gray-400 text-sm pt-2 px-6">
                    {data.subTitle}
                </p>
            </div>
            <div id="contain" className="leading-8 mb-10 text-lg font-light">
                {data.contents}
            </div>
            </div>
        </div>
    )
}

function Modal({showDetails, showTitle, toggleDetails, content}){
    return (
        <div id="popup-modal-0" tabIndex="-1" className={`fixed top-0 left-0 right-0 z-50  overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full ${showDetails>-1?'flex':'hidden'} justify-center items-center`}>
    <div className="relative w-full h-full max-w-md p-4 md:h-auto">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <button type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center " data-modal-toggle="popup-modal" onClick={()=>toggleDetails(-1)}>
                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>  
            </button>
            <div className="p-6 text-center">
                {/* <svg className="mx-auto mb-4 text-gray-400 w-14 h-14 dark:text-gray-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg> */}
                <h3 className="mb-5 text-lg font-normal text-white">{showTitle}</h3>
                <p className="py-4 pb-6 mb-6">{content}</p>
                <button data-modal-toggle="popup-modal" type="button" className="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2" onClick={()=>toggleDetails(-1)}>
                    CLOSE
                </button>
                {/* <button data-modal-toggle="popup-modal" type="button" className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">No, cancel</button> */}
            </div>
        </div>
    </div>
</div>
    )
}