import './App.css';
import DesktopApp from './DesktopApp'
import MobileApp from './MobileApp'
import AdminApp from './AdminApp'

import 'aos/dist/aos.css';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import React from 'react';

function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/app/*' exact element={<MobileApp />} />
          <Route path='/admin/*' exact element={<AdminApp />} />
          <Route path='*' exact element={<DesktopApp />} />
        </Routes>
      </BrowserRouter>
      
    </div>
  );
}

export default App;
