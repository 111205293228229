import React, { useRef } from "react";
import { useEffect } from "react";
import './carousel.css'
import ImgWithFallback from "./ImageWithFallback";

const images = [
    '/images/shutterstock_1510777181.jpg',
    '/images/2ndcarousel.jpg', 
    '/images/shutterstock_686728723.jpg', 
    '/images/shutterstock_1887505027.jpg',
    // '/images/shutterstock_2107183901.jpg'
]

const captions = [
    'Learn proper setups & chart reading',
    'Trading made simple with Logic & Reasoning',
    'Let the chart do the talking',
    'Risk Management, Profit Booking & more',
    // 'Leverage your time by using PRICE ACTION'
]

// images must be an array of urls , if using Next JS this could something like
// const images = ['/img/img1.png', '/img/img2.png', '/img/img3.png']
// images must be an array of urls , if using Next JS this could something like
// const images = ['/img/img1.png', '/img/img2.png', '/img/img3.png']

const Carousel = ({mobile = false}) => {
  // We will start by storing the index of the current image in the state.
  const [currentImage, setCurrentImage] = React.useState(0);

  // We are using react ref to 'tag' each of the images. Below will create an array of
  // objects with numbered keys. We will use those numbers (i) later to access a ref of a
  // specific image in this array.
  let intervalCache = null
  const nextRef = useRef(null)
  
  // let refs = images.reduce((acc, val, i) => {
  //               acc[i] = React.createRef();
  //               return acc;
  //             }, {});

  let carRefs = [useRef(null), useRef(null), useRef(null), useRef(null)]

  const scrollToImage = i => {
    
    // First let's set the index of the image we want to see next
    
    setCurrentImage(i);
    
    // Now, this is where the magic happens. We 'tagged' each one of the images with a ref,
    // we can then use built-in scrollIntoView API to do eaxactly what it says on the box - scroll it into
    // your current view! To do so we pass an index of the image, which is then use to identify our current
    // image's ref in 'refs' array above.
    // console.log(carRefs[i], currentImage, i)
   
  };

  useEffect(() => {
    carRefs[currentImage].current.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start'
    });
  }, [currentImage])

  // Some validation for checking the array length could be added if needed
  const totalImages = images.length;

  // Below functions will assure that after last image we'll scroll back to the start,
  // or another way round - first to last in previousImage method.
  const nextImage = () => {
    if (currentImage >= totalImages - 1) {
      setCurrentImage(0);
    } else {
      setCurrentImage(currentImage + 1);
    }
  };

  const previousImage = () => {
    
    if (currentImage === 0) {
      setCurrentImage(totalImages - 1);
    } else {
      setCurrentImage(currentImage - 1);
    }

  };

  useEffect(() => {
    
    // if(intervalCache){
    //   clearTimeout(intervalCache)
    // }

    // intervalCache = setTimeout(() => {
    //   // console.log(nextRef.current.classList, carRefs)
    //   // setCurrentImage(currentImage+1)
    //   nextImage()
    // }, 5000);

  }, [currentImage])

  // Tailwind styles. Most importantly notice position absolute, this will sit relative to the carousel's outer div.
  const arrowStyle = 'absolute text-white text-2xl z-10 bg-black h-10 w-10 rounded-full flex items-center justify-center'; //opacity-75 

  // Let's create dynamic buttons. It can be either left or right. Using
  // isLeft boolean we can determine which side we'll be rendering our button
  // as well as change its position and content.
  const sliderLeft = isLeft => (
    <button
      type="button"
      onClick={isLeft ? previousImage : nextImage}
      className={`${arrowStyle} ${isLeft ? 'left-2 ml-2' : 'right-2 mr-2'}`}
      style={{ top: '40%' }}
    >
      <span role="img" aria-label={`Arrow ${isLeft ? 'left' : 'right'}`}>
        {isLeft ? <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
          </svg> : <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
        </svg>}
      </span>
    </button>
  );

  const sliderRight = () => (
    <button
      type="button"
      ref={ nextRef }
      onClick={ nextImage }
      className={`${arrowStyle} ${'right-2 mr-2'}`}
      style={{ top: '40%' }} >
      <span role="img" aria-label={`Arrow right`}>
        { <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
        </svg>}
      </span>
    </button>
  );

  return (
    <div className="max-w-7xl mx-auto px-4 mt-4 flex justify-center w-screen md:h-1/3 items-center"  >
      <div className="relative w-full hidden md:flex" style={{maxHeight: 250, maxWidth: '100%', overflow: 'hidden'}}>
        <div className="carousel">
          {sliderLeft(true)}
          {images.map((img, i) => (
            <figure className="w-full flex-shrink-0" key={i} ref={carRefs[i]} style={{ overflow: "hidden", maxHeight: '100%' }}>
              {/* <img src={img} className="w-full h-full object-cover c-image" loading="lazy-load" style={{}} /> */}
              <ImgWithFallback src={img.replace('.jpg', '.webp')} className="w-full h-full object-cover c-image" fallback={img}/>
              {<figcaption className="caption p-2 mt-4" >
                {captions[i]}
              </figcaption>}
              <a href="#courses" onClick={() => {
                if(window.Android) {  window.Android.showToast(""); }
              }} className="animate-bounce rounded-xl font-bold hover:bg-blue-800 bg-theme-blue py-4 px-6 text-white learn-btn">START LEARNING</a>
            </figure>
          ))}
          {sliderRight()}
        </div>
      </div>
      <div className="relative w-full md:hidden">
        <figure className="w-full flex-shrink-0" style={{ overflow: "hidden", textAlign: 'center'}}>
          <img src="/images/shutterstock_1510777181.jpg" className="border-radius c-image"/>
          {<figcaption className="caption p-2 text-center text-2xl font-bold"  style={{width: '100%', textIndent: 0, textAlign: 'center', fontSize: 20}}>
          Learn proper setups <br /> & chart reading
          </figcaption>}
          <a href={mobile?'coursepage':"#courses"} className=" inline-block rounded-xl font-bold hover:bg-blue-800 bg-theme-blue py-4 px-6 text-white learn-btn">START LEARNING</a>
        </figure>
        
      </div>
    </div>
  );
};



export default Carousel