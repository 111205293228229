import firebaseApp from '../firebase'
import { getAuth } from "firebase/auth";
import { getFirestore, doc, onSnapshot } from "firebase/firestore"
import Constant from './Constant';
const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);


class Stat{
    static async syncUserCount(){
        const token = await auth.currentUser.getIdToken(true)
        return fetch(process.env.REACT_APP_ADMIN_URL+'/admin/count-user-stat', { method: 'POST', body: JSON.stringify({}), headers: { ftoken: token } })
    }
    static async syncPaidCount(){
        const token = await auth.currentUser.getIdToken(true)
        return fetch(process.env.REACT_APP_ADMIN_URL+'/admin/calculate-paid-orders', { method: 'GET',  headers: { ftoken: token } })
    }
    
    static hookUserCount(callback = () => {}) {
        return onSnapshot(doc(db, Constant.BASE_COLLECTION, "stats"), callback)
    }
}

export default Stat