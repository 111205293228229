import firebaseApp from '../firebase'
import { getAuth } from "firebase/auth";
import { collection, doc, getDocs, getFirestore, onSnapshot, query, where } from 'firebase/firestore';
import Constant from './Constant';
const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp)

class StreamCollection{


    static async fetchList() {
        const token = await auth.currentUser.getIdToken(true)
        return fetch(`${process.env.REACT_APP_ADMIN_URL}/admin/collection-list`, {
            method: 'GET',
            headers: { ftoken: token , 'Content-Type': 'application/json' }
        })
    }

    static async fetchFBList( uid, onChange = () => {} ) {
        console.log(uid)
        return onSnapshot(query(collection(db, Constant.BASE_COLLECTION, "stream_collections", "data"), where('uid', '==', uid)), onChange)
    }

    static async fetchFBVideoList( bunnyCollectionId, uid, onChange = () => {} ) {
        console.log(bunnyCollectionId, uid)
        return onSnapshot(query(collection(db, Constant.BASE_COLLECTION, "stream_collections", "data", bunnyCollectionId, "videos"), where('uid', '==', uid)), onChange)
    }

    static async createCollection( name = "" ){
        // console.log(JSON.stringify({ name }))
        const token = await auth.currentUser.getIdToken(true)
        return fetch(`${process.env.REACT_APP_ADMIN_URL}/admin/collection-create`, {
            method: 'POST',
            headers: { ftoken: token , 'Content-Type': 'application/json' },
            body: JSON.stringify({ name })
        })
    }

    static async fetchVideos(collectionId = '') {
        const token = await auth.currentUser.getIdToken(true)
        return fetch(`${process.env.REACT_APP_ADMIN_URL}/admin/video-list?collectionId=${collectionId}`, {
            method: 'GET',
            headers: { ftoken: token , 'Content-Type': 'application/json'},
        })
    }

    static async getTusHeaderFile(file, videoId, collectionId){
        // collectionId here is useless
        const token = await auth.currentUser.getIdToken(true)
        return fetch(`${process.env.REACT_APP_ADMIN_URL}/admin/video-tusheader`, {
            method: 'POST',
            headers: { ftoken: token , 'Content-Type': 'application/json' },
            body: JSON.stringify({ fileName: file.name, video: videoId, collection: collectionId })
        })
    }

    static async syncVideo(videoId, collectionId){
        const token = await auth.currentUser.getIdToken(true)
        return fetch(`${process.env.REACT_APP_ADMIN_URL}/admin/video-collection-sync`, {
            method: 'POST',
            headers: { ftoken: token , 'Content-Type': 'application/json' },
            body: JSON.stringify({ videoId, collectionId })
        })
    }

    static async getMainCollection(){
        // gets bunny collection data
        const token = await auth.currentUser.getIdToken(true)
        return fetch(`${process.env.REACT_APP_ADMIN_URL}/admin/video-collection-home`, {
            method: 'GET',
            headers: { ftoken: token , 'Content-Type': 'application/json' }
        })
    }

    static async deleteVideo(collectionId, videoGuid){
        const token = await auth.currentUser.getIdToken(true)
        return fetch(`${process.env.REACT_APP_ADMIN_URL}/admin/video-delete`, {
            method: 'DELETE',
            headers: { ftoken: token , 'Content-Type': 'application/json' },
            body:  JSON.stringify({collectionId, videoGuid})
        })
    }

}

export default StreamCollection