import { getAuth } from "firebase/auth";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import Stat from "../models/Stat";

export default ({}) => {

    const [statCount, setStatCount] = useState({ users: 0, paidOrders: 0})
    useEffect(() => {
        Stat.hookUserCount((doc) => {
            if(doc && doc.data()){
                console.log(doc.data())
                setStatCount({...statCount, ...doc.data()})
            } else {
                console.log("Syncing stats")
                syncStat()
            }
        })
    }, [])

    function syncStat() {
        Stat.syncUserCount()
        Stat.syncPaidCount()
        alert("Sync triggered. Stat will update in few second to minutes.")
    }
    return (
        <div className="max-w-7xl mx-auto px-4 mt-4">
            <h4 className="text-white mt-4 mb-4"><a href="/admin/home">Admin Home</a></h4>
            <div className="flex">
                <h4 className="text-white"><small><i>Welcome</i></small> <b>{getAuth().currentUser.displayName}</b></h4>
            </div>
            <div className="flex"> 
                <div className="flex-auto">
                    <div className="bg-gray-800 text-center text-white m-2 p-4">
                        <h2 className="mb-4">{statCount.users}</h2>
                        <h5>TOTAL ORDERS</h5>
                    </div>
                </div>
                <div className="flex-auto">
                    <div className="bg-gray-800 text-center text-white m-2 p-4">
                        <h2 className="mb-4">{statCount.paidOrders}</h2>
                        <h5>PAID ORDERS</h5>
                    </div>
                </div>
            </div>
            <button onClick={() => { syncStat() }} className={'text-white p-2 bg-yellow-800 ml-2 rounded'} >SYNC STAT (Last updated at {statCount.lastUpdate ? moment(statCount.lastUpdate.toDate()).format('HH:mm A DD MMM Y'): ''})</button>
            <div className="mt-4 bg-gray-900" style={{height: 4}}></div>
            <p className="mt-4">&nbsp;</p>
            <div>
            <a href="/admin/users" className="p-4 bg-gray-800 text-white m-2 rounded hover:bg-gray-700 cursor-pointer uppercase">Users</a>
            <a href="/admin/admins" className="p-4 bg-gray-800 text-white m-2 rounded hover:bg-gray-700 cursor-pointer uppercase">Admins</a>
            <a href="/admin/next-date" className="p-4 bg-gray-800 text-white m-2 rounded hover:bg-gray-700 cursor-pointer uppercase">Next Date</a>
            <a href="/admin/orders" className="p-4 bg-gray-800 text-white m-2 rounded hover:bg-gray-700 cursor-pointer uppercase">Orders</a>
            <a href="/admin/courses" className="p-4 bg-gray-800 text-white m-2 rounded hover:bg-gray-700 cursor-pointer uppercase">Courses</a>
            <a href="/admin/videos" className="p-4 bg-gray-800 text-white m-2 rounded hover:bg-gray-700 cursor-pointer uppercase">Videos</a>
            <a href="/admin/notification" className="p-4 bg-gray-800 text-white m-2 rounded hover:bg-gray-700 cursor-pointer uppercase">Notification</a>
            </div>
            <button 
                onClick={() => { 
                    getAuth().signOut()
                    .then(() => window.location.href = "/admin/home" )
                    .catch((err) => { 
                        console.log(err); alert("Logout failed!"); }) 
                    }} 
                className={'text-white p-2 bg-red-800 ml-2 rounded'} 
                style={{marginTop: 200}} >LOGOUT
            </button>
            
        </div>)
}
