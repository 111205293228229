import { getAuth } from "firebase/auth";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import Stat from "../models/Stat";
import DateElement from "../components/DateElement";
import firebaseApp from '../firebase'
const auth = getAuth(firebaseApp);

export default ({}) => {

    const [loading, setLoading] = useState(false)
    const [customDate, setCustomDate] = useState(null)
    const [deKey, setdeKey] = useState(0)

    function getIdToken() {
        return auth.currentUser.getIdToken(true)
    }
   
    async function removeCustomDate() {
        let con = window.confirm("Are you sure?")
        if(con){
            const token = await getIdToken()
            try {
                setLoading(true)
                await fetch(`${process.env.REACT_APP_ADMIN_URL}/admin/remove-date`, {
                    method: 'GET',
                    headers: { ftoken: token , 'Content-Type': 'application/json' }
                })
                setdeKey(deKey+1)
                alert("Success")
            } catch (error) {
                console.log(error)
                alert(error.message)
            } finally{
                setLoading(false)
            }
        }
    }

    async function setCustomDateCall(e) {
        e.preventDefault()
        if(customDate.trim().length < 3) return alert("Please type the date in proper format")
        const token = await getIdToken()
        try {
            setLoading(true)
            await fetch(`${process.env.REACT_APP_ADMIN_URL}/admin/next-date`, {
                method: 'POST',
                body: JSON.stringify({dates: customDate}),
                headers: { ftoken: token , 'Content-Type': 'application/json' }
            })
            setdeKey(deKey+1)
            alert("Success")
        } catch (error) {
            console.log(error)
            alert(error.message)
        } finally{
            setLoading(false)
        }
    }


    return (
        <div className="max-w-7xl mx-auto px-4 mt-4">
            <h4 className="text-white mt-4 mb-4"><a href="/admin/home">Admin Home</a> / Next Date</h4>
            <div className="flex">
                <h4 className="text-white"><small><i>Welcome</i></small> <b>{getAuth().currentUser.displayName}</b></h4>
            </div>
            <div className="flex flex-col py-6"> 
                <div>
                    <h4 className="text-white">Next Date: <DateElement key={deKey} /></h4>
                    <button className="btn" onClick={removeCustomDate} disabled={loading}>Remove Custom Date</button>
                </div>
                <div>
                    <form>
                        <h4 className="text-white">Enter Custom date below - </h4>
                        <input className="input p-2" type="text" onChange={(e) => setCustomDate(e.target.value)} disabled={loading}></input>
                        <button className="bg-red-400 p-2" onClick={(e) => setCustomDateCall(e)} disabled={loading}>SAVE</button>
                    </form>
                </div>
            </div>
            <div className="mt-4 bg-gray-900" style={{height: 4}}></div>
            <p className="mt-4">&nbsp;</p>
            <div>
            <a href="/admin/users" className="p-4 bg-gray-800 text-white m-2 rounded hover:bg-gray-700 cursor-pointer uppercase">Users</a>
            <a href="/admin/admins" className="p-4 bg-gray-800 text-white m-2 rounded hover:bg-gray-700 cursor-pointer uppercase">Admins</a>
            <a href="/admin/orders" className="p-4 bg-gray-800 text-white m-2 rounded hover:bg-gray-700 cursor-pointer uppercase">Orders</a>
            <a href="/admin/courses" className="p-4 bg-gray-800 text-white m-2 rounded hover:bg-gray-700 cursor-pointer uppercase">Courses</a>
            <a href="/admin/videos" className="p-4 bg-gray-800 text-white m-2 rounded hover:bg-gray-700 cursor-pointer uppercase">Videos</a>
            <a href="/admin/notification" className="p-4 bg-gray-800 text-white m-2 rounded hover:bg-gray-700 cursor-pointer uppercase">Notification</a>
            </div>
            <button 
                onClick={() => { 
                    getAuth().signOut()
                    .then(() => window.location.href = "/admin/home" )
                    .catch((err) => { 
                        console.log(err); alert("Logout failed!"); }) 
                    }} 
                className={'text-white p-2 bg-red-800 ml-2 rounded'} 
                style={{marginTop: 200}} >LOGOUT
            </button>
            
        </div>)
}
