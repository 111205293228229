import React, { useEffect, useState } from "react";
import StreamCollection from "../models/Collection";
import firebaseApp from '../firebase';
import { getAuth } from "firebase/auth";
import { useParams } from "react-router-dom";
import * as tus from "tus-js-client";
import './adminCollection.css'
import moment from "moment";
const auth = getAuth(firebaseApp);

export default function AdminVideos({ }) {

    let [data, setdata] = useState([])
    let [mainCollection, setMainCollection] = useState({})
    let [page, setPage] = useState(1)
    let [itemsPerPage, setItemsPerPage] = useState(100)
    // const {mainCollection.guid} = useParams()
    // const [selectedCourseIndex, setSelectedCourseIndex] = useState(-1)

    useEffect(() => {

        // StreamCollection.fetchVideos(mainCollection.guid)
        // .then((resp) => resp.json())
        // .then(err => console.log(err))

        StreamCollection.getMainCollection()
        .then((resp) => resp.json())
        .then((resp) => {
            console.log(resp)
            setMainCollection(resp)
            if(resp.guid){
                // debugger
                // StreamCollection.fetchFBVideoList(resp.guid, auth.currentUser.uid, (snapshot) => {
                //     console.log(snapshot.docs.map(v => v.data()))
                //     let docs = snapshot.docs.map(v => v.data())
                //     setdata(docs)
                //     let nonSynced = docs.filter(v => !v.encodeProgress || v.encodeProgress != 100)
                //     if(nonSynced.length >= 1) {
                //         console.log('Syncing videos, ', nonSynced)
                //         syncVideo(nonSynced[0].guid)
                //     }
                // })
                StreamCollection.fetchVideos() // mainCollection.guid
                .then((resp) => resp.json())
                .then((resp) => {
                    setdata(resp)
                })
                .catch(err => console.log(err))
            }
        })

        
        // syncVideo("5d7486de-bd05-4616-b0ee-bccab2b53eb1")
        // setdata(JSON.parse(JSON.stringify(stagingCourseData)))
    }, [])

    // function addVideo () { 
    //     StreamCollection.getTusHeaderFile("", "somethings")
    //     // let collectionName = prompt('Enter collection name...')
    //     // if(collectionName){
    //     //     StreamCollection.createCollection(collectionName)
    //     //     .then((resp) => resp.json())
    //     //     .then((resp) => console.log(resp))
    //     //     .catch(error => console.log(error))
    //     // }
    // }

    async function uploadFile(event) {
        let file = event.target.files[0]
        if(file){
            let headers = await (await StreamCollection.getTusHeaderFile(file, null, mainCollection.guid)).json()
            console.log(headers)
            initTusUpload(file, headers.videoId, headers.libraryId, headers.expires, headers.signature)
        }
        // expires: 1667362351463
        // libraryId: "70980"
        // signature: "8a6c41b5eb7f99062cdd151dbbd3460cce13c74e148672d97a1c0df98610826d"
        // videoId: "bc9ccd59-90f0-4c76-b8db-536ca7a7c448"
    }

    function initTusUpload(file, VideoId, LibraryId, AuthorizationExpire, AuthorizationSignature = "738e1c9f8e7b40d247cd0c1b04eb94e536fe132fa192293aeaa21b60184e6dfe") {
        
        // StreamCollection.syncVideo(VideoId, mainCollection.guid)
        
        var upload = new tus.Upload(file, {
            endpoint: "https://video.bunnycdn.com/tusupload",
            retryDelays: [0, 3000, 5000, 10000, 20000, 60000, 60000],
            headers: {
                AuthorizationSignature, // SHA256 signature (library_id + api_key + expiration_time + video_id)
                AuthorizationExpire, // Expiration time as in the signature,
                VideoId, // The guid of a previously created video object through the Create Video API call
                LibraryId,
            },
            metadata: {
                filetype: file.type,
                title: file.name,
            },
            onError: function (error) {
                console.log(error)
            },
            onProgress: function (bytesUploaded, bytesTotal) {
                console.log(bytesUploaded, bytesTotal)
            },
            onSuccess: function () {
                console.log('Done')
                // syncVideo(VideoId)
            }
        })
        
        // Check if there are any previous uploads to continue.
        upload.findPreviousUploads().then(function (previousUploads) {
            // Found previous uploads so we select the first one. 
            if (previousUploads.length) {
                upload.resumeFromPreviousUpload(previousUploads[0])
            }
        
            // Start the upload
            upload.start()
        })
    }

    function syncVideo(videoId) {
        StreamCollection.syncVideo(videoId, mainCollection.guid)
    }

    function deleteVideo(video) {
        if(window.confirm("Are you sure to delete file? It is not revertable.")){
            StreamCollection.deleteVideo(mainCollection.guid, video.guid)
        }
    }

    function formatBytes(bytes,decimals) {
        if(bytes == 0) return '0 Bytes';
        var k = 1024,
            dm = decimals || 2,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
     }

    return (
        <div className="max-w-7xl mx-auto px-4 mt-4">
            <h2 className="text-white" style={{marging: '20px 0'}}>Manage Videos</h2>
            {(data.items || []).map((v, sn) => (
                <div key={v.guid} className="text-white rounded-lg border-slate-50 dark:bg-gray-800 p-4 mt-4">
                    <div className="flex">
                        <div className="flex-none w-14 mr-2">
                            {mainCollection && mainCollection.cdnHostName && v.thumbnailFileName && <img className="mt-2" src={`https://${mainCollection.cdnHostName}/${v.guid}/${v.thumbnailFileName}`}/>}
                        </div>
                        <div className="flex-1 w-64"> 
                            <h2 className="mb-1">{v.title} <Badge video={v}/></h2> 
                            <div className="text-small">
                                 Size :<i><b>{formatBytes(v.storageSize)}</b> Last updated - <b><i>{moment(v.dateUploaded).add(5.5, 'hours').format('h:mm A Do MMM Y')}</i></b></i> 
                            </div>
                        </div>
                        <div className="flex-1 text-right">
                            {v.encodeProgress && v.encodeProgress>=100 ? <a href={`https://iframe.mediadelivery.net/play/${v.videoLibraryId}/${v.guid}`} target={"_blank"} className="bg-blue-800 p-2 mr-1 rounded">View</a>:''}
                            {/* <button className="bg-yellow-800 p-2 mr-1 rounded">Edit</button> */}
                            <button className="bg-red-800 p-2 pt-1 pb-1 rounded" onClick={() => deleteVideo(v)}>Delete</button>
                        </div>
                    </div>
                </div>
            ))}
            {(data.items || []).length<1?<div colSpan={3} className="text-center text-white p-4 bg-gray-700">No video uploaded!</div>:<></>} 
            {/* <button className="rounded-lg dark:bg-blue-800 mt-4 p-4 text-white" onClick={() => addVideo()}>+ Upload Video</button> */}
            <label className="rounded-lg dark:bg-blue-800 mt-4 p-2 text-white cursor-pointer inline-block">
                Upload Video
                <input onChange={(event) => uploadFile(event)} type={'file'} accept={'video/*'} style={{visibility: 'hidden', width: 0, height: 0}}/>
            </label>
            {/* {selectedCourseIndex >- 1 && <EditCourse courses={data} index={selectedCourseIndex} editClosed={editClosed} saveCourse={saveCourse}/>} */}
        </div>
    )
}

function Badge({video}) {
    return <>{video.encodeProgress && video.encodeProgress==100?<span className="bg-green-800 p-1 rounded text-small">Encoded</span>:<span className="bg-yellow-800 p-1 rounded text-small">InProgress</span>}</>
}
