import './App.css';

// import TNC from './components/TNC';
// import AboutUs from './components/AboutUs';
// import ContactUs from './components/ContactUs';
// import PrivacyPolicy from './components/PrivacyPolicy';
// import RefundPolicy from './components/Refund';
import Home from './components/Home';

import CourseDetails from './components/CourseDetails';
import LiveClassApp from './components/LiveClassApp';
import LiveClassApp2 from './components/LiveClassApp2';
import { Routes, Route } from "react-router-dom";
import React, {Suspense} from 'react';
import Psychology from './components/Psychology';
import LiveClassAppTest from './components/LiveClassAppTest';
// import MyLearningApp from './components/MyLearningApp';


const MyLearningApp = React.lazy(() => import('./components/MyLearningApp'))

const PrivacyPolicy = React.lazy(() => import('./components/PrivacyPolicy'))
const RefundPolicy = React.lazy(() => import('./components/Refund'))
const TNC = React.lazy(() => import('./components/TNC'))
const ContactUs = React.lazy(() => import('./components/ContactUs'))
const AboutUs = React.lazy(() => import('./components/AboutUs'))


function MobileApp() {

  return (
    <div className="App">
        <Routes>
          <Route path='/course-details-app' exact element={<CourseDetails mobile={true} />} />
          <Route path='/live-class-details-app' exact element={<LiveClassApp hideEnrollButton={false}/>} />
          <Route path='/live-class-details-app-2' exact element={<LiveClassApp2 hideEnrollButton={false}/>} />
          <Route path='/live-class-details-app-test' exact element={<LiveClassAppTest hideEnrollButton={false}/>} />
          <Route path='/test-live-class' exact element={<LiveClassApp2 hideEnrollButton={false}/>} />
          <Route path='/terms-and-conditions-app' exact element={<Suspense fallback={<div>Loading...</div>}><TNC mobile={true} /></Suspense>} />
          <Route path='/refund-policy-app' exact element={<Suspense fallback={<div>Loading...</div>}><RefundPolicy mobile={true} /></Suspense>} />
           <Route path='/psychology-details-app' exact element={<Psychology mobile={true} />} />
          <Route path='/about-us-app' exact element={<Suspense fallback={<div>Loading...</div>}><AboutUs mobile={true} /></Suspense>} />
          <Route path='/privacy-policy-app' exact element={<Suspense fallback={<div>Loading...</div>}><PrivacyPolicy mobile={true} /></Suspense>} />
          <Route path='/contact-us-app' exact element={<Suspense fallback={<div>Loading...</div>}><ContactUs mobile={true} /></Suspense>} />
          <Route path='/my-learning-app' exact element={<Suspense fallback={<div>Loading...</div>}><MyLearningApp mobile={true}/></Suspense>} />
          <Route path='/' exact element={<Home mobile={true}/>} />
        </Routes>
    </div>
  );
}
export default MobileApp;
