import React, { useEffect, useState } from "react";
import './adminCollection.css'
import FBNotification from "../models/FBNotification";
import Course from "../models/Course";
import Swal from 'sweetalert2'

export default function AdminNotification({ }) {

    let [data, setdata] = useState({
        title: '', body: '', click_action: '',//MainActivity CourseViewActivity
        data: { },
        email: ''
    })

    let [notificationTypes, setNotificationTypes] = useState([
        'Normal', 'Course List', 'Live Class', 'Free Videos', 'Course Page'
    ])

    let [isTest, setIsTest] = useState(false)


    let [activeNotificationType, setActiveNotificationType] = useState( 'Normal' )
    let [courseList, setCourseList] = useState([])
    

    useEffect(() => {

    }, [])

    function ValidateEmail(mail) 
        {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
        {
            return (true)
        }
            return (false)
        }

    function sendNotification() {
        if(activeNotificationType==='Course Page' && !data.data.course_id){
            return alert("Select a course to continue!")
        }
        if(!data.title){
            return alert("Title cannot be empty!")
        }
        if(!data.body){
            return alert("Body cannot be empty!")
        }

        if( data.email && data.email.length > 1 ){
            if(!ValidateEmail(data.email)) {
                return alert("Invalid email ID!")
            }
        }
        
        Swal.fire({
            title: 'Queuing up the notification...',
            showLoaderOnConfirm: true,
            allowOutsideClick: false,
            preConfirm: () => {
                return FBNotification.sendNotification(data).then((resp) => resp.json())
            }
        })
        
        .then((resp) => {
            if(resp.isConfirmed){
                Swal.fire({
                    title: 'Notification will be delivered shortly!',
                    icon: 'success',
                    showConfirmButton: false,
                    toast: true,
                    timer: 1800,
                    didClose: () => {
                        window.location.reload()
                    }
                })
            }
            
            setdata({
                title: '', body: '', click_action: 'MainActivity',// CourseViewActivity
                data: {
                },
                email: ''
            })
           
        })
        .catch(err => {
            console.log(err)
            Swal.fire({
                title: 'Notification failed to queue up!',
                icon: 'error'
            })
        })
        Swal.clickConfirm()
    }

    function changeNotificationType(type) {
        setActiveNotificationType(type)

        if(type === 'Normal'){
            setdata({
                ...data,
                click_action: '',// CourseViewActivity
                data: { 
                    fragment_id: ''
                },
            })
        }
        else if(type === 'Course List'){
            setdata({
                ...data, 
                click_action: 'MainActivity',
                data: {
                    fragment_id: 'course_list'
                },
            })
        }
        else if(type === 'Live Class'){
            setdata({
                ...data, 
                click_action: 'MainActivity',
                data: {
                    fragment_id: 'live_class'
                },
            })
        }
        else if(type === 'Free Videos'){
            setdata({
                ...data, 
                click_action: 'MainActivity',
                data: {
                    fragment_id: 'free_videos'
                },
            })
        }
        else if(type === 'Course Page'){
            setdata({
                ...data, 
                click_action: 'CourseViewActivity',
                data: {
                    course_id: ''
                },
            })
            if(courseList.length < 1) {
                Course.getV1CourseArrayDoc("optiontrading").then((resp) => {
                    console.log(resp.data().data)
                    try {
                        let courseArray = (resp.data().data || []).filter(v => v.status === 'active')
                        setCourseList(courseArray)
                        console.log(resp.data().data , courseArray)

                    } catch (error) {
                        console.log(error)
                    }
                })
            }
        }
    }

    function changeTestToggle(checked) {
        setIsTest(checked)

        if ( checked ) {

        } else {
            setdata({
                ...data,
                email: ''
            })
        }
    }


    return (
        <div className="max-w-7xl mx-auto px-4 mt-4">
            <h2 className="text-white mt-4" style={{marging: '20px 0'}}>Send Notification</h2>
            {/* TAB START */}
            <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
                <ul className="flex flex-wrap -mb-px">
                    {notificationTypes.map(type =>  { return (<li className="mr-2" key={type}>
                        <a href="#" onClick={() => { changeNotificationType(type) }} className={`inline-block p-4 rounded-t-lg border-b-2 ${type!==activeNotificationType?`border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300`:`border-blue-600 active dark:text-blue-500 dark:border-blue-500 text-blue-600`} `}>{type}</a>
                    </li>)})}
                </ul>
            </div>
            {/* TAB END */}
            <div className="text-white">
                {activeNotificationType==='Course Page' && <div className="mt-2">
                    <label className="block">Select Course </label>
                    <select id="countries" defaultValue={""} onChange={(e) => setdata({
                        ...data,
                        data: { course_id: e.target.value }
                    }) } className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        <option value={""} selected>Choose a course</option>
                       {courseList.map(cours => { return (<option key={cours.course_id} value={cours.course_id}>{cours.title}</option>) }) }
                    </select>
                </div>}
                <div className="mt-2">
                    <label className="block">Title</label>
                    <input className="block text-black p-2" style={{width: '100%'}} defaultValue={data.title} onChange={e => setdata({...data, title: e.target.value})} placeholder="Type notification title"/>
                </div>
                <div className="mt-2">
                    <label className="block">Body </label>
                    <textarea className="block text-black p-2" style={{width: '100%'}} defaultValue={data.body} onChange={e => setdata({...data, body: e.target.value})} placeholder="Type notification body"></textarea>
                </div>

                <div className="mt-2">
                    <label className="block"><input type={'checkbox'} onChange={(e) => {changeTestToggle(e.target.checked)}}/> Test notification on email account - </label>
                    <input disabled={!isTest} type={'email'} className="block text-black p-2" style={{width: '100%'}} defaultValue={''} onChange={e => setdata({...data, email: e.target.value})} placeholder="Type test email" />
                </div>
              

                <div className="mt-4">
                    <button className="mt-2 p-2 bg-blue-900 rounded" style={{width: '100%'}} onClick={sendNotification}>SEND</button>
                </div>
                
            </div>
        </div>
    )
}

