import './App.css';
import Header from './components/Header'
import Home from './components/Home';
import Footer from './components/Footer';
import CourseDetails from './components/CourseDetails';
import LiveClass from './components/LiveClass';
import LiveClassApp from './components/LiveClassApp';
import 'aos/dist/aos.css';
import { Routes, Route, Navigate } from "react-router-dom";
import React, {Suspense} from 'react';
import Psychology from './components/Psychology';
import RiskManagementCourse from './components/RiskManagementCourse';
import AdvanceCourse from './components/AdvanceCourse';

const Admin = React.lazy(() => import('./admin/Admin'))
const Disclaimer = React.lazy(() => import('./components/Disclaimer'))
const MyLearningApp = React.lazy(() => import('./components/MyLearningApp'))

const PrivacyPolicy = React.lazy(() => import('./components/PrivacyPolicy'))
const RefundPolicy = React.lazy(() => import('./components/Refund'))
const TNC = React.lazy(() => import('./components/TNC'))
const ContactUs = React.lazy(() => import('./components/ContactUs'))
const AboutUs = React.lazy(() => import('./components/AboutUs'))

function DesktopApp() {

  return (
    <div className="App">
      <Header />
        <Routes>
          <Route path='/terms-and-conditions' exact element={<Suspense fallback={<div>Loading...</div>}><TNC /></Suspense>} />
          <Route path='/refund-policy' exact element={<Suspense fallback={<div>Loading...</div>}><RefundPolicy /></Suspense>} />
          <Route path='/course-details' exact element={<CourseDetails />} />
          <Route path='/live-class-details' exact element={<LiveClass />} />
          <Route path='/advance-course-details' exact element={<Navigate to="/live-class-details" />} eelement={<AdvanceCourse />} />
          <Route path='/psychology-details' exact element={<Psychology />} />
          <Route path='/advanced-risk-management' exact element={<RiskManagementCourse />} />
          <Route path='/about-us' exact element={<Suspense fallback={<div>Loading...</div>}><AboutUs /></Suspense>} />
          <Route path='/privacy-policy' exact element={<Suspense fallback={<div>Loading...</div>}><PrivacyPolicy /></Suspense>} />
          <Route path='/contact-us' exact element={<Suspense fallback={<div>Loading...</div>}><ContactUs /></Suspense>} />
          <Route path='/disclaimer' exact element={<Suspense fallback={<div>Loading...</div>}><Disclaimer /></Suspense>} />

          <Route path='/admin' element={<Suspense fallback={<div>Loading...</div>}><Admin/></Suspense>} ></Route>
          <Route path='/admin-secret' element={<Suspense fallback={<div>Loading...</div>}><Admin type="live-class"/></Suspense>}></Route>
          <Route path='/my-learning-app' exact element={<Suspense fallback={<div>Loading...</div>}><MyLearningApp mobile={true}/></Suspense>} />

          {/* FOR OLD APP VERSION */}
          <Route path='/course-details-app' exact element={<CourseDetails mobile={true} />} />
          <Route path='/live-class-details-app' exact element={<LiveClassApp marginTop={50} hideEnrollButton={true}/>} />
          <Route path='/terms-and-conditions-app' exact element={<TNC mobile={true} />} />
          <Route path='/refund-policy-app' exact element={<RefundPolicy mobile={true} />} />
          <Route path='/psychology-details-app' exact element={<Psychology mobile={true} />} />
          <Route path='/advanced-risk-management-app' exact element={<RiskManagementCourse mobile={true} />} />
          <Route path='/about-us-app' exact element={<AboutUs mobile={true} />} />
          <Route path='/privacy-policy-app' exact element={<PrivacyPolicy mobile={true} />} />
          <Route path='/contact-us-app' exact element={<ContactUs mobile={true} />} />

          <Route path='/' exact element={<Home />} />

        </Routes>
      
      <Footer />
      <div id='bottom-bar' className="w-full bg-black border-t shadow-indigo-200 py-2 px-4 md:px-20 flex justify-between items-center" style={{position: 'sticky', bottom: 0, borderColor: '#ffffff40'}}>
          <p className="text-white"> 
          <span className="text-xs">Join our LIVE CLASS and learn how to trade in a simplified manner. </span></p>
          <a href="/live-class-details" aria-label='live class details' target={"_blank"} rel="noreferrer" style={{borderRadius: '2px'}} className="px-2 md:px-4 py-2 text-sm font-medium text-white hover:text-slate-700 hover:bg-white duration-150 bg-theme-blue2">MORE&nbsp;INFO</a>
      </div>
    </div>
  );
}

// function Home ({mobile = false}){
//   return (
//     <>
//       {mobile && <link rel="stylesheet" type="text/css" href={'mobileapp.css'} />}
//       <div className="content pb-2">
//         <Carousel mobile={mobile}/>
//         <Features mobile={mobile}/>
        
//         {!mobile &&<><Courses></Courses>
//          <WhyUs />
//         <Testimony /></>}
//       </div>
//     </>
//   )
// }
export default DesktopApp;
