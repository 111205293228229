import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTelegram} from '@fortawesome/free-brands-svg-icons'
import { faChartLine, faUsers } from "@fortawesome/free-solid-svg-icons";

export default ({mobile = false}) => {
    const features = [
        {
            title: 'Structured Learning ',
            content: 'We take you through the basics of Option Trading to the advanced with simple learning methodology using pure PRICE ACTION',
            icon: <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
          </svg>
        },
        {
            title: 'Doubt Clearing Sessions',
            content: 'Get each and every trade of yours checked and every doubt cleared directly one on one through voice notes every Saturday.',
            icon: <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
          </svg>

        },
        {
            title: 'Real Time Support',
            content: <>Join our <a href="https://t.me/optiontradingoriginal" target={"_blank"} ><u>Free Telegram Group</u></a> to get the real time updates of videos that are uploaded.</>,
            icon: <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M5.05 3.636a1 1 0 010 1.414 7 7 0 000 9.9 1 1 0 11-1.414 1.414 9 9 0 010-12.728 1 1 0 011.414 0zm9.9 0a1 1 0 011.414 0 9 9 0 010 12.728 1 1 0 11-1.414-1.414 7 7 0 000-9.9 1 1 0 010-1.414zM7.879 6.464a1 1 0 010 1.414 3 3 0 000 4.243 1 1 0 11-1.415 1.414 5 5 0 010-7.07 1 1 0 011.415 0zm4.242 0a1 1 0 011.415 0 5 5 0 010 7.072 1 1 0 01-1.415-1.415 3 3 0 000-4.242 1 1 0 010-1.415zM10 9a1 1 0 011 1v.01a1 1 0 11-2 0V10a1 1 0 011-1z" clipRule="evenodd" />
          </svg>
        },
        {
            title: 'Learning Videos',
            content: 'Access to curated videos with real time trade to provide you the practical experience',
            icon: <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clipRule="evenodd" />
          </svg>
        },
        {
            title: 'Simplified Methodology',
            content: 'Use only PRICE ACTION, understand the chart and create positions with low risk and high reward ratio',
            icon: <FontAwesomeIcon icon={faChartLine} />
        //     <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
        //     <path fillRule="evenodd" d="M5.05 3.636a1 1 0 010 1.414 7 7 0 000 9.9 1 1 0 11-1.414 1.414 9 9 0 010-12.728 1 1 0 011.414 0zm9.9 0a1 1 0 011.414 0 9 9 0 010 12.728 1 1 0 11-1.414-1.414 7 7 0 000-9.9 1 1 0 010-1.414zM7.879 6.464a1 1 0 010 1.414 3 3 0 000 4.243 1 1 0 11-1.415 1.414 5 5 0 010-7.07 1 1 0 011.415 0zm4.242 0a1 1 0 011.415 0 5 5 0 010 7.072 1 1 0 01-1.415-1.415 3 3 0 000-4.242 1 1 0 010-1.415zM10 9a1 1 0 011 1v.01a1 1 0 11-2 0V10a1 1 0 011-1z" clipRule="evenodd" />
        //   </svg>
        },
        {
            title: 'Weekend Live Class',
            content: 'Interact freely, improve your learning by joining our weekend class',
            icon: <FontAwesomeIcon icon={faUsers} />
        //     <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
        //     <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clipRule="evenodd" />
        //   </svg>
        }
    ]
    return (
        <div className="container px-4 mx-auto  py-15 bg-opacity-10" id="features">
            <a className={(mobile?'':"mt-2")+" text-xl text-center block mt-6"} style={{color: '#3fbfef'}} href="https://t.me/optiontradingoriginal" target={"_blank"} aria-label="Telegram"><FontAwesomeIcon icon={faTelegram}  /> Join our Free Telegram Group (For latest video upload notifications)</a>
            <p className="text-center text-sm mt-2" style={{color:'#fff'}}>"We do not offer any investment/account handling or calls/tips services"</p>
            {/* style={{backgroundImage: "url('https://www.toptal.com/designers/subtlepatterns/uploads/dot-grid.png')" }} */}
            <div id="title" className="text-center my-10">
                <h1 className="font-bold text-4xl text-white opacity-90">FEATURES</h1>
                <p className="text-light text-gray-500 text-xl mt-6">
                    {/* The only trading course required to grow your wealth */}
                </p>
                </div>

        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-10 group bg-dark shadow-neutral-100 ">
            {features.map( (feat, i) => <div
                className="p-10 flex flex-col items-center text-center group  hover:bg-gray-900 cursor-pointer" data-aos={mobile?'':"fade-up"} data-aos-offset="-100"
                data-aos-anchor-placement="bottom-bottom" key={i}>
                <span className="p-5 rounded-full bg-dark-100 border border-gray-600 hover:sky-500 text-gray-200  shadow-red-200" style={{width: 62, height: 62}}>
                    {feat.icon || <svg
                        xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor" stroke-width="1.5">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>}</span>
                <p className="text-xl font-medium text-white mt-3">{feat.title}</p>
                <p className="mt-2 text-md text-slate-400">{feat.content}</p>
            </div>)}

            {/* <div
                className="p-10 flex flex-col items-center text-center group bg-gray-900 hover:bg-gray-800 cursor-pointer">
                <span className="p-5 rounded-full bg-orange-500 text-white  shadow-orange-200"><svg
                        xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor" stroke-width="1.5">
                        <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                        <polyline points="14 2 14 8 20 8"></polyline>
                        <line x1="16" y1="13" x2="8" y2="13"></line>
                        <line x1="16" y1="17" x2="8" y2="17"></line>
                        <polyline points="10 9 9 9 8 9"></polyline>
                    </svg></span>
                <p className="text-xl font-medium text-white mt-3">Best
                    Test preparation</p>
                <p className="mt-2 text-sm text-slate-500">Know where you stand and what next to do to succeed .</p>
            </div>

            <div className="p-10 flex flex-col items-center text-center group    bg-gray-900 hover:bg-gray-800 cursor-pointer">
                <span className="p-5 rounded-full bg-yellow-500 text-white  shadow-yellow-200"><svg
                        xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor" stroke-width="1.5">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z" />
                    </svg></span>
                <p className="text-xl font-medium text-white mt-3">Admission process Guidance</p>
                <p className="mt-2 text-sm text-slate-500">Professional Advice for higher education abroad and select the
                    top institutions worldwide.</p>
            </div>


            <div className="p-10 flex flex-col items-center text-center group   bg-gray-800 cursor-pointer">
                <span className="p-5 rounded-full bg-lime-500 text-white  shadow-lime-200"><svg
                        xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor" stroke-width="1.5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                    </svg></span>
                <p className="text-xl font-medium text-white mt-3">Best
                    Track Record</p>
                <p className="mt-2 text-sm text-slate-500">Yet another year ! Yet another jewel in our crown!</p>
            </div>

            <div className="p-10 flex flex-col items-center text-center group    bg-gray-800 cursor-pointer">
                <span className="p-5 rounded-full bg-teal-500 text-white  shadow-teal-200"><svg
                        xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor" stroke-width="1.5">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
                    </svg></span>
                <p className="text-xl font-medium text-white mt-3">Free
                    Mock Exams</p>
                <p className="mt-2 text-sm text-slate-500">Get Topic-Wise Tests, Section- Wise and mock tests for your
                    preparation.</p>
            </div>

            <div className="p-10 flex flex-col items-center text-center group     bg-gray-900 hover:bg-gray-800 cursor-pointer">
                <span className="p-5 rounded-full bg-indigo-500 text-white  shadow-indigo-200"><svg
                        xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor" stroke-width="1.5">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                    </svg></span>
                <p className="text-xl font-medium text-white mt-3">Genuine
                    Visa Advice</p>
                <p className="mt-2 text-sm text-slate-500">Visa process by helping you create the necessary documentation
                </p>
            </div> */}




        </div>

        {/* <div className="w-full   bg-indigo-600 shadow-indigo-200 py-10 px-20 flex justify-between items-center">
            <p className=" text-white"> <span className="text-4xl font-medium">Still Confused ?</span> <br/> <span className="text-lg">Book For Free Career Consultation Today ! </span></p>
            <button className="px-5 py-3  font-medium text-slate-700  hover:bg-white duration-150  bg-yellow-400">BOOK AN APPOINTMENT </button>
        </div> */}

    </div>
    )
}