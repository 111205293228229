import * as firebaseui from "firebaseui";
import 'firebaseui/dist/firebaseui.css'
import React, { useEffect, useState } from "react";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import firebaseApp from '../firebase'
import ldata from './data'
import playlistJSON from './playlist'
import Course from "../models/Course";
import CourseList from "./CourseList";
import BreadCrumb from "../components/BreadCrumb";
const auth = getAuth(firebaseApp);
// const ui = new firebaseui.auth.AuthUI(auth);
  
export default ({type}) => {

    const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.
    const [carData, setCaraouselData] = useState([])
    const [publishedCourseData, setPublishedCourseData] = useState([])
    const [stagingCourseData, setStagingCourseData] = useState([])
    const [classData, setclassData] = useState(ldata.live_class)
    const [playlistData, setplaylistData] = useState(playlistJSON)

    // Listen to the Firebase Auth state and set the local state.
    useEffect(() => {
        // auth.onAuthStateChanged(user => {
        //     setIsSignedIn(!!user);
        //     if(!user){
        //         // ui.start('#container', {
        //         //     signInFlow: 'popup',
        //         //     signInOptions: [
        //         //         GoogleAuthProvider.PROVIDER_ID
        //         //     ]
        //         // })
        //     } else {
        //         fetchCourseData()
        //     }
        //   });
          fetchCourseData()
    }, []);

    async function fetchCourseData() {
        let courseDoc = await Course.getV1CourseArrayDoc() //  getDoc(doc(db, "optiontrading", "static", "v1", "course"))
        setPublishedCourseData(getData(courseDoc.data() || []))

        // let stagingCourseDoc = await Course.getStagingCourseArrayDoc()
        // setStagingCourseData(getData(stagingCourseDoc.data() || []))
        Course.getStagingCourseArraySnapshot((snapshot) => {
            // console.log(snapshot.data())
            setStagingCourseData(getData(snapshot.data() || []))
        })
        // console.log(stagingCourseDoc.data().data)
    }

    function getData(data) {
        try {
            return data.data
        } catch (error) {
            return []
        }
    }

    // async function syncFromProductionToStaging(){
    //     console.log(stagingCourseData)
    //     await setDoc(doc(db, "optiontrading", "static", "staging", "course"), {data: stagingCourseData.map(v => { v.published = true; return v })})
    // }
  
    // if (!isSignedIn) {
    //   return (
    //     <div>
    //       <h1>My App</h1>
    //       <p>Please sign-in:</p>
    //       <div id="container"></div>
    //     </div>
    //   );
    // }

    // let saveCaraouselData = async function(){
    //     let data = {}
    //     try {
    //         data = JSON.parse(carData)
    //     } catch (error) {
    //         console.log(error)
    //     }
    //     try {
    //         await setDoc(doc(db, "optiontrading", "static", "v1", "caraousel"), {data})
    //         alert('Saved')
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    // let saveCourseData = async function(){
    //     let data = {}
    //     try {
    //         data = JSON.parse(stagingCourseData)
    //     } catch (error) {
    //         console.log(error)
    //     }
    //     try {
    //         console.log(data)
    //         await setDoc(doc(db, "optiontrading", "static", "v1", "course"), {data})
    //         data.forEach(async item => {
    //             try {
    //                 console.log(item)
    //                 await setDoc(doc(db, "optiontrading", "static", "v1", "course", "data", item.course_id), item)
    //             } catch (error) {
    //                 console.log(error)
    //             }
    //         })
    //         alert('Saved')
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    // let saveLiveClassData = async function(){
    //     let data = {}
    //     try {
    //         data = JSON.parse(classData)
    //     } catch (error) {
    //         console.log(error)
    //     }
    //     try {
    //         await setDoc(doc(db, "optiontrading", "static", "v1", "live_class"), {data})
    //         alert('Saved')
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    // let savePlayListData = async function() {
    //     let data = {}
    //     try {
    //         data = JSON.parse(playlistData)
    //     } catch (error) {
    //         console.log(error)
    //     }
    //     try {
    //         await setDoc(doc(db, "optiontrading", "static", "v1", "playlist"), {data})
    //         alert('Saved')
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    // let saveSubscriptionData = async function() {
    //     let uid = "bnY0RQxPFjMjXAyBqr7GcK0C4zE2"
    //     let itemId = "arm1"
    //     let item = null
    //     try {
    //         let data = JSON.parse(stagingCourseData)
    //         console.log(data)
    //         item = data.find(v => v.course_id == itemId)
    //         item.expiresAt = new Date(2022,11,10)
    //     } catch (error) {
    //         console.log(error)
    //     }

    //     try {
    //         await setDoc(doc(db, "optiontrading", "user_subscriptions", uid, itemId), item)
    //         alert('Saved')
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    // function updateVideo(selectedVideo) {
    //     let newCourseData = []
    //     for (let index = 0; index < stagingClonedCourseData.length; index++) {
    //         newCourseData.push(stagingClonedCourseData[index]);
    //     }
    //     delete selectedVideo.is_new
    //     newCourseData[selectedCourseIndex].videos[selectedVideoIndex] = selectedVideo
    //     // console.log(newCourseData)
    //     debugger
    //     setSelectedVideoIndex(-1)
    //     let temp = selectedCourseIndex
    //     setSelectedCourseIndex(-2)
    //     setSelectedCourseIndex(temp)
    //     setClonedStagingCourseData(newCourseData)
    //     // courseEditor.onAddVideo(selectedVideo)
    //     // let temp = selectedCourseIndex
    //     // setSelectedCourseIndex(-1)
    //     // invokeEditCourse(temp)
    // }

    // async function invokeEditCourse(index) {
    //     setClonedStagingCourseData(JSON.parse(JSON.stringify(stagingCourseData)))
    //     setSelectedCourseIndex(index); 
    // }

    // function saveCourse(course) {
    //     let temp = [...stagingClonedCourseData]
    //     console.log(stagingClonedCourseData)
    //     temp[selectedCourseIndex] = course
    //     setClonedStagingCourseData(temp)
    //     console.log(temp)
    //     // trigger save Firebase sync and update cloned staging data
    //     setStagingCourseData(temp)
    // }

    // function addCourse() {
    //     let length = stagingCourseData.length
        
    //     let newCourse = Course.newJSON()

    //     let temp = JSON.parse(JSON.stringify(stagingCourseData)).concat([newCourse])
    //     setClonedStagingCourseData(temp)
    //     setSelectedCourseIndex(length)
    // }

    return (
    <div className="max-w-7xl mx-auto px-4 mt-4">
        <BreadCrumb data={[{href: '/admin/home', name: 'Admin Home'}, {href: '#', name: 'Course'}]}/>
        {/* <h2 className="text-white mt-4 mb-4">Manage Course</h2> */}
        <div>
            <CourseList stagingCourseData={stagingCourseData || []}/>
        </div> 
        {/* {selectedCourseIndex >- 1 && <EditCourse courses={stagingClonedCourseData} index={selectedCourseIndex} editClosed={() => { setSelectedCourseIndex(-1); setClonedStagingCourseData([]) }} saveCourse={saveCourse}/>} */}
        {/* {selectedCourseIndex >- 1 && selectedVideoIndex > -1 && <EditVideo videos={stagingClonedCourseData[selectedCourseIndex]?stagingClonedCourseData[selectedCourseIndex].videos:[]} index={selectedVideoIndex} editClosed={() => setSelectedVideoIndex(-1)} updateVideo={(video) => updateVideo(video)}/>} */}
        {/* <button onClick={() => syncFromProductionToStaging()}>HELLO</button> */}

    </div>)
}
