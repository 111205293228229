import firebaseApp from '../firebase'
import { getFirestore, setDoc, doc, getDoc, collection, onSnapshot } from "firebase/firestore"
const db = getFirestore(firebaseApp);

class Course {

    propKeys = ["title", "image", "short_description",  "description", "course_id", "audience" , "type" , "language", "instructor", "demo_video", "demo_video_thumb", "price", "price_value", "validity", "validity_day", "videos", "is_new"]

    static baseCollection = "optiontrading" // optiontrading // "optiontrading" // 

    static async getV1CourseArrayDoc(overrideCollection = null) {
        return getDoc(doc(db, overrideCollection?overrideCollection:this.baseCollection, "static", "v1", "course"))
    }

    static async getStagingCourseArrayDoc() {
        return getDoc(doc(db, this.baseCollection, "static", "staging", "course"))
    }


    static async getStagingCourseArraySnapshot(onChange = () =>{}) {
        return onSnapshot(doc(db, this.baseCollection, "static", "staging", "course"), onChange)
    }

    static async getV1CourseArraySnapshot() {
        return onSnapshot(doc(db, this.baseCollection, "static", "v1", "course"))
    }



    static getNewId() {
        return doc(collection(db, this.baseCollection, "static", "staging")).id
    }

    static async saveV1CourseData(data){

        let proms = []
        proms.push( setDoc(doc(db, this.baseCollection, "static", "v1", "course"), {data}) )

        data.forEach(async item => {
            try {
                proms.push(setDoc(doc(db, this.baseCollection, "static", "v1", "course", "data", item.course_id), item))
            } catch (error) {
                console.log(error)
            }
        })

        return Promise.all(proms)
    }

    static async saveStagingCourseData(data){

        let proms = []
        proms.push( setDoc(doc(db, this.baseCollection, "static", "staging", "course"), {data}) )
        
        data.forEach(async item => {
            try {
                proms.push(setDoc(doc(db, this.baseCollection, "static", "v1", "staging", "data", item.course_id), item))
            } catch (error) {
                console.log(error)
            }
        })

        return Promise.all(proms)
    }

    static newJSON(){
        return {
            "title" : "",
            "image" : "",
            "short_description": "",
            "description": "",
            "course_id": this.getNewId(),
            "audience": "beginner",
            "type" : "course",
            "language": "Hindi",
            "instructor": "Prateek Varshney",
            "demo_video": "",
            "demo_video_thumb": null,
            "price": "0.00 /-",
            "price_value": 0,
            "validity": "1 month",
            "validity_day": 30,
            "videos": [],
            "status": "coming_soon",
            "is_new": true
        }
    }

    static newVideo(){
        return {
            "title": "",
            "duration": "0m 0s",
            "type": "free",
            "status": "active",
            "video": "",
            "video_path": "",
            "thumb": null,
            "is_new": true
        }
    }

    static validityText(validityday){
        let validityDayValue = validityday >= 30 ? validityday/30 : validityday
        return validityday >= 30 ? `${validityDayValue} Month${validityDayValue>1?'s':''}` :  `${validityday} Day${validityDayValue>1?'s':''}`
    }

    static fromJSON(json){
        
        let course = new Course()
        
        this.propKeys.forEach( key =>  course[key] = json[key] )
        
        return course
    }

    static toJSON(course){
        
        let json = {}
        
        this.propKeys.forEach( key =>  json[key] = course[key] )
        
        return json
    }

}

export default Course