import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import Aos from 'aos';

// import app from './firebase.js'

window.Aos = Aos

const rootElement = document.getElementById("root");
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );


// if (rootElement.hasChildNodes()) {
//   const hydrate = ReactDOM.hydrateRoot(rootElement);
//   hydrate.render(<React.StrictMode><App /></React.StrictMode>, rootElement);
// } else {
//   const root = ReactDOM.createRoot(rootElement);
//   root.render(<React.StrictMode><App /></React.StrictMode>);
// }
const root = ReactDOM.createRoot(rootElement);
root.render(<React.StrictMode><App /></React.StrictMode>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

window.onload = function(){
  Aos.init()
}

setTimeout(() => {
  Aos.refreshHard()
}, 1000);
