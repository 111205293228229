import React, { useEffect } from "react";
import ClipboardJS from "clipboard";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default ({mobile = false }) => {
    const UPIID = ['varshneyenterprise@icici']

    const lectures = [
        'Lecture 1 : Best Plan For Beginners', 
        'Lecture 2 : Reduce your STOPLOSS',
        'Lecture 3 : Spare Quantities Concept (Extremely Important)',
        'Lecture 4 : Position Splitting'
    ]
    var clipboard = null
    function clip(){
        
        if(clipboard){
           return  
        }
        clipboard = new ClipboardJS('.btn');

        clipboard.on('success', function(e) {
            console.info('Text:', e.text);
            toast( e.text + " Copied!")
            e.clearSelection();
        });

    }

    useEffect(() => {
        clip()
    })

    return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 py-6" style={{color: 'white', minHeight: 200}}>
         {mobile && <link rel="stylesheet" type="text/css" href={'mobileapp.css'} />}
         
        <h2 className="text-center text-4xl my-4">Advance Risk Management</h2>
        <div>
            <div className="rounded-lg border border-gray-800 p-4">
                <iframe src="https://www.youtube.com/embed/cl8j8J0_P5Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" style={{margin: '0 auto', padding: 20, maxWidth:"100%", width:"500px", height: "300px"}} allowfullscreen></iframe>
                <h2 className="mb-2 text-center">These 4 lectures are going to change your trading journey entirely. You will see.</h2>
                <ol className="my-4">
                    {lectures.map((v, bi) => {
                        return (<li className="rounded-lg bg-gray-800 mt-4 p-4 mb-4 " key={bi}><b>{v}</b></li>)
                    })}
                </ol>
            </div>
            <h2 className="mb-2 mt-4 pt-4 text-center">PRICE: ₹ 1499 /- only (validity 1 month)</h2>

            <div className="flex justify-center  my-6 py-6">
                <a _href="https://t.me/optiontradingonlyadmin" href="https://bit.ly/3TeWGAG" target={"_blank"} className="text-center block font-medium text-xl p-4 rounded-xl hover:shadow-lg transition duration-200 ease-in-out bg-sky-500 text-white"
                style={{maxWidth: 400, fontWeight: "bold"}} >ENROLL NOW</a>
            </div>
        </div>
    
    </div>)
    
}
