export default {
        "caraousel": [
            
            {
                "title" : "Live Class",
                "image" : "https://optiontradingonly.in/app-images/car5.jpg",
                "short_description": "S1",
                "description": "L1",
                "type" : "liveclass"
            },
            {
                "title" : "Trading Psychology",
                "image" : "https://optiontradingonly.in/app-images/psychology.png",
                "short_description": "4 Lectures",
                "description": "L1",
                "course_id": "p1",
                "audience": "beginner",
                "type" : "course",
                "language": "Hindi",
                "instructor": "Prateek Varshney",
                "demo_video": null,
                "price": "299.00 /-",
                "price_value": 29900,
                "demo_video_thumb": null,
                "videos": [
                    {
                        "title": "FEAR OF LOSS!",
                        "duration": "9m 5s",
                        "type": "paid",
                        "status": "active"
                    },
                    {
                        "title": "Greed Management with Trailing STOPLOSS",
                        "duration": "9m 5s",
                        "type": "paid",
                        "status": "active"
                    },
                    {
                        "title": "Controlling the Emotions while trading (Extremely Important)",
                        "duration": "9m 5s",
                        "type": "paid",
                        "status": "active"
                    },
                    {
                        "title": "Working on your Confidence through logic and reasoning",
                        "duration": "9m 5s",
                        "type": "paid",
                        "status": "active"
                    }
                ],
                "status": "active"
            },
            // {
            //     "title" : "Basics of Option Trading",
            //     "image" : "https://optiontradingonly.in/app-images/basic.png",
            //     "course_id": "b1",
            //     "short_description": "8 Lectures",
            //     "description": "L1",
            //     "audience": "beginner",
            //     "videos": [],
            //     "type" : "course",
            //     "language": "Hindi",
            //     "instructor": "Prateek Varshney",
            //     "demo_video": null,
            //     "demo_video_thumb": null,
            //     "price": '999.00 /-',
            //     "price_value": 99900,
            //     "validity": "1 month",
            //     "validity_day": 30,
            //     "status": "active"
            // },
            {
                "title" : "Advance Option Strategies",
                "course_id": "a1",
                "image" : "https://optiontradingonly.in/app-images/advanced.png",
                "short_description": "Coming soon",
                "description": "L1",
                "audience": "advanced",
                "videos": [],
                "type" : "course",
                "language": "Hindi",
                "instructor": "Prateek Varshney",
                "demo_video": null,
                "demo_video_thumb": null,
                "validity": "1 month",
                "validity_day": 30,
                "price": "XXX /-",
                "price_value": 0,             
                "status": "coming_soon"
            }
    ],
    "courses": [
        {
            "title" : "Trading Psychology",
            "image" : "https://optiontradingonly.in/app-images/psychology.png",
            "short_description": "4 Lectures",
            "description": "L1",
            "course_id": "p1",
            "audience": "beginner",
            "type" : "course",
            "language": "Hindi",
            "instructor": "Prateek Varshney",
            "demo_video": "https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8",
            "demo_video_thumb": null,
            "price": "299.00 /-",
            "price_value": 29900,
            "validity": "1 month",
            "validity_day": 30,
            "videos": [
                {
                    "title": "Course Details",
                    "duration": "1m 46s",
                    "type": "free",
                    "status": "active",
                    "video": "https://vz-f2295a2d-7a5.b-cdn.net/198232e3-9523-422e-97c1-ead334dadbba/playlist.m3u8",
                    "video_path": "/198232e3-9523-422e-97c1-ead334dadbba",
                    "thumb": null
                },
                {
                    "title": "FEAR OF LOSS!",
                    "duration": "14m 53s",
                    "type": "paid",
                    "status": "active",
                    "video": "https://vz-f2295a2d-7a5.b-cdn.net/f97a3923-e7a3-4e79-ab2b-c9e3dbf62a85/playlist.m3u8",
                    "video_path": "/f97a3923-e7a3-4e79-ab2b-c9e3dbf62a85",
                    "thumb": null
                },
                {
                    "title": "Greed Management with Trailing STOPLOSS",
                    "duration": "9m 40s",
                    "type": "paid",
                    "status": "active",
                    "video": "https://vz-f2295a2d-7a5.b-cdn.net/bf55c45d-e83b-4918-a0ca-8b2d87e85333/playlist.m3u8",
                    "video_path": "/bf55c45d-e83b-4918-a0ca-8b2d87e85333",
                    "thumb": null
                },
                {
                    "title": "Controlling the Emotions while trading (Extremely Imp.)",
                    "duration": "9m 58s",
                    "type": "paid",
                    "video": "https://vz-f2295a2d-7a5.b-cdn.net/c676ab67-f2d9-4aed-8c95-01b289799a38/playlist.m3u8",
                    "video_path": "/c676ab67-f2d9-4aed-8c95-01b289799a38",
                    "status": "active",
                    "thumb": null
                },
                {
                    "title": "Working on your Confidence through logic and reasoning",
                    "duration": "10m 7s",
                    "type": "paid",
                    "status": "active",
                    "video": "https://vz-f2295a2d-7a5.b-cdn.net/78d424e7-d046-4cfa-936e-6c0ef30033d5/playlist.m3u8",
                    "video_path": "/78d424e7-d046-4cfa-936e-6c0ef30033d5",
                    "thumb": null
                }
            ],
            "status": "active"
        },
        {
            "title" : "Advance Risk Management",
            "image" : "https://optiontradingonly.in/app-images/advanced-risk-management.jpg",
            "short_description": "4 Lectures",
            "description": "ARM1",
            "course_id": "arm1",
            "audience": "advance",
            "type" : "course",
            "language": "Hindi",
            "instructor": "Prateek Varshney",
            "demo_video": "",
            "demo_video_thumb": null,
            "price": "1499.00 /-",
            "price_value": 149900,
            "validity": "1 month",
            "validity_day": 30,
            "videos": [
                {
                    "title": "Course Details",
                    "duration": "1m 46s",
                    "type": "free",
                    "status": "active",
                    "video": "https://vz-f2295a2d-7a5.b-cdn.net/e57cbda8-bf50-4228-8eab-e312bdbbaeff/playlist.m3u8",
                    "video_path": "/e57cbda8-bf50-4228-8eab-e312bdbbaeff",
                    "thumb": null
                },
                {
                    "title": "Best Plan For Beginners",
                    "duration": "10m 18s",
                    "type": "paid",
                    "status": "active",
                    "video": "https://vz-f2295a2d-7a5.b-cdn.net/f97a3923-e7a3-4e79-ab2b-c9e3dbf62a85/playlist.m3u8",
                    "video_path": "/f97a3923-e7a3-4e79-ab2b-c9e3dbf62a85",
                    "thumb": null
                },
                {
                    "title": "Reduce your STOPLOSS",
                    "duration": "11m 14s",
                    "type": "paid",
                    "status": "active",
                    "video": "https://vz-f2295a2d-7a5.b-cdn.net/22e1e286-ff49-4c4d-a635-e738da2fa2e1/playlist.m3u8",
                    "video_path": "/22e1e286-ff49-4c4d-a635-e738da2fa2e1",
                    "thumb": null
                },
                {
                    "title": "Spare Quantities Concept (Extremely Important)",
                    "duration": "10m 22s",
                    "type": "paid",
                   
                    "video": "https://vz-f2295a2d-7a5.b-cdn.net/62d88d78-3872-4d5d-9c50-9282d08be93e/playlist.m3u8",
                    "video_path": "/62d88d78-3872-4d5d-9c50-9282d08be93e",
                    "status": "active",
                    "thumb": null
                },
                {
                    "title": "Position Splitting",
                    "duration": "8m 26s",
                    "type": "paid",
                    "status": "active",
                    "video": "https://vz-f2295a2d-7a5.b-cdn.net/69f33af0-1e88-4557-b32f-1e0d26c7d3c4/playlist.m3u8",
                    "video_path": "/69f33af0-1e88-4557-b32f-1e0d26c7d3c4",
                    "thumb": null
                }
            ],
            "status": "active"
        },
        {
            "title" : "Live Class",
            "image" : "https://optiontradingonly.in/app-images/basic.png",
            "course_id": "b1",
            "short_description": "1 month mentorship",
            "description": "L1",
            "audience": "beginner",
            "videos": [],
            "type" : "live_class",
            "language": "Hindi",
            "instructor": "Prateek Varshney",
            "demo_video": "https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8",
            "demo_video_thumb": null,
            "price": "4,999.00 /-",
            "price_value": 499900,
            "validity": "1 month",
            "validity_day": 30,
            "status": "active"
        },
        // {
        //     "title" : "ABCD of Trading",
        //     "course_id": "abcd",
        //     "image" : "https://optiontradingonly.in/app-images/BasicTrading.png",
        //     "short_description": "Coming soon",
        //     "description": "L1",
        //     "audience": "advanced",
        //     "videos": [],
        //     "type" : "course",
        //     "language": "Hindi",
        //     "instructor": "Prateek Varshney",
        //     "demo_video": "https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8",
        //     "price": "XXX /-",
        //     "price_value": 0,         
        //     "validity": "1 month",
        //     "validity_day": 30,
        //     "demo_video_thumb": null,
        //     "status": "coming_soon"
        // },
        // {
        //     "title" : "Advance Option Trading",
        //     "course_id": "a1",
        //     "image" : "https://optiontradingonly.in/app-images/Fibonaci.png",
        //     "short_description": "Coming soon",
        //     "description": "L1",
        //     "audience": "advanced",
        //     "videos": [],
        //     "type" : "course",
        //     "language": "Hindi",
        //     "instructor": "Prateek Varshney",
        //     "demo_video": "https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8",
        //     "price": "XXX /-",
        //     "price_value": 0,         
        //     "validity": "1 month",
        //     "validity_day": 30,
        //     "demo_video_thumb": null,
        //     "status": "coming_soon"
        // },
        // {
        //     "title" : "Option Selling",
        //     "course_id": "osc",
        //     "image" : "https://optiontradingonly.in/app-images/OptionSelling.png",
        //     "short_description": "Coming soon",
        //     "description": "L1",
        //     "audience": "advanced",
        //     "videos": [],
        //     "type" : "course",
        //     "language": "Hindi",
        //     "instructor": "Prateek Varshney",
        //     "demo_video": "https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8",
        //     "price": "XXX /-",
        //     "price_value": 0,         
        //     "validity": "1 month",
        //     "validity_day": 30,
        //     "demo_video_thumb": null,
        //     "status": "coming_soon"
        // },
    ], 
    "live_class": [
        {
            "schedule": "27th-28th Aug, 11:00 am and 9:00 pm"
        }
    ]
}