import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Dashboard } from '@uppy/react'
import Uppy from '@uppy/core'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import FileInput from '@uppy/file-input'
import Tus from '@uppy/tus'
import * as tus from "tus-js-client";
import StreamCollection from '../models/Collection'

export default function EditVideo({videos = [], index, editClosed = () => {}, updateVideo = () => {}}) {
  const [open, setOpen] = useState(true)

  const cancelButtonRef = useRef(null)
  // const [allVideos] = useState(videos)
  const [selectedVideo, setSelectedVideo] = useState(null)

  const [uppyInstance, setUppyInstance] = useState(null)

  const [uploadRequest, setUploadRequest] = useState(null)

  const [uploadPercent, setUploadPercent] = useState(0)

  useEffect(() => {
    if(index>-1) setOpen(true)
    else setOpen(false)
  }, [index])

  useEffect(() => {
    console.log("HELLO START", videos, selectedVideo)
    setSelectedVideo({...videos[index]})
    setUppyInstance(new Uppy({
      autoProceed: false,
       allowMultipleUploadBatches: false,
      restrictions: { 
        maxNumberOfFiles: 1,
        allowedFileTypes: ['video/*'],
      }
    }).use(FileInput)
    .use(Tus, {
      endpoint: "https://video.bunnycdn.com/tusupload",
      retryDelays: [0, 3000, 5000, 10000, 20000, 60000, 60000],
      // headers: {
      //     AuthorizationSignature, // SHA256 signature (library_id + api_key + expiration_time + video_id)
      //     AuthorizationExpire, // Expiration time as in the signature,
      //     VideoId, // The guid of a previously created video object through the Create Video API call
      //     LibraryId,
      // },
      // metadata: {
      //     filetype: file.type,
      //     title: file.name,
      // },
      async onBeforeRequest(req, file){
        // get Tus header parameters
        let headers = await (await StreamCollection.getTusHeaderFile(file, null, null)).json()
        
        req.setHeader('AuthorizationSignature', headers.signature)
        req.setHeader('AuthorizationExpire', headers.expires)
        req.setHeader('VideoId', headers.videoId)
        req.setHeader('LibraryId', headers.libraryId)

        setUploadRequest(headers)
      },  
      onShouldRetry(err, retryAttemptNumber, option, next){
        console.log(err, retryAttemptNumber, option)
        next(false)
      },  
      onAfterResponse(req, res){
        console.log(res.getStatus(), res.getBody())
        // if(res.getStatus() == 204){
        //   let videoUrl = `https://${uploadRequest.cdnHostName}/${uploadRequest.videoId}/playlist.m3u8`
        //   setSelectedVideo({...selectedVideo, video: videoUrl, video_path: `/${uploadRequest.videoId}`})
        // }
      },
      // onError: function (error) {
      //     console.log(error)
      // },
      // onProgress: function (bytesUploaded, bytesTotal) {
      //     console.log(bytesUploaded, bytesTotal)
      // },
      // onSuccess: function () {
      //     console.log('Done')
      //     // syncVideo(VideoId)
      //     let videoUrl = `https://CDN/${uploadRequest.videoId}/playlist.m3u8`
      //     setSelectedVideo({...selectedVideo, video: videoUrl, video_path: `/${uploadRequest.videoId}`})
      // }
    }))
  }, [index])

  useEffect(() => {
    if(open==false) {
        editClosed()
    }
  }, [open])



  function saveVideo() {
    console.log(selectedVideo)
    updateVideo(selectedVideo)
    setOpen(false)
  }

  function durationChange(e) {
    let value = parseFloat(e.target.value) || 0
    let minute = parseInt(value/60)
    let seconds = parseInt(value%60)
    setSelectedVideo({...selectedVideo, duration: `${minute>0?`${minute}m `:''} ${seconds>0?`${seconds}s`:''}`})
  }

  function videUrlChange(v) {
    try {
      let vurl = v.target.value
      let vpath = vurl.split('.net')[1].split('\/playlist.m3u8')[0]
      setSelectedVideo({...selectedVideo, video: vurl, video_path: vpath})
    } catch (error) {
      setSelectedVideo({...selectedVideo, video: v.target.value, video_path: ''})
    }
  }

  function cancelEdit() {
    setOpen(false)
    editClosed()
    updateVideo(selectedVideo, Boolean(selectedVideo.is_new))
  }

  async function uploadVideo(event) {
    let file = event.target.files[0]
    if(file){
      let headers = await (await StreamCollection.getTusHeaderFile(file, null, null)).json()
      setUploadRequest(headers)

      var upload = new tus.Upload(file, {
        endpoint: "https://video.bunnycdn.com/tusupload",
        retryDelays: [0, 3000, 5000, 10000, 20000, 60000, 60000],
        headers: {
            AuthorizationSignature: headers.signature, // SHA256 signature (library_id + api_key + expiration_time + video_id)
            AuthorizationExpire: headers.expires, // Expiration time as in the signature,
            VideoId: headers.videoId, // The guid of a previously created video object through the Create Video API call
            LibraryId: headers.libraryId,
        },
        metadata: {
            filetype: file.type,
            title: file.name,
        },
        onError: function (error) {
            console.log(error)
        },
        onBeforeRequest: function(req) {
          setUploadPercent(1)
        },
        onProgress: function (bytesUploaded, bytesTotal) {
            console.log(bytesUploaded, bytesTotal)
            setUploadPercent((bytesUploaded/bytesTotal)*100)
        },
        onSuccess: function () {
            console.log('Done')
            setUploadPercent(0)
              let videoUrl = `https://${headers.cdnHostName}/${headers.videoId}/playlist.m3u8`
            setSelectedVideo({...selectedVideo, video: videoUrl, video_path: `/${headers.videoId}`})
        }
    })
    
    // Check if there are any previous uploads to continue.
    upload.findPreviousUploads().then(function (previousUploads) {
        // Found previous uploads so we select the first one. 
        if (previousUploads.length) {
            upload.resumeFromPreviousUpload(previousUploads[0])
        }
    
        // Start the upload
        upload.start()
    })
    }
  }


  
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={cancelEdit}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-black text-left shadow-xl transition-all sm:w-full ">
                <div className="bg-gray px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="flex sm:items-start pb-4 mb-4" style={{ maxHeight: 'calc(100vh - 40px)', overflowY: 'auto'}}>
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left" style={{width: '100%'}}>
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-white">
                        {selectedVideo && selectedVideo.is_new?'Add':'Edit'} Video
                      </Dialog.Title>
                      {selectedVideo && <div className="mt-2 flex">
                        <div className='d-block w-full p-4'>
                            <div className="mb-6">
                                <label htmlFor="title" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Title</label>
                                <input type="text" id="title" defaultValue={selectedVideo.title} onChange={(v) => selectedVideo.title = v.target.value} className="d-block text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white " placeholder="" required />
                            </div>
                            {/* <div className="mb-6">
                                <label htmlFor="duration" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Duration in Seconds</label>
                                <input type="number" id="duration" min={0} defaultValue={selectedVideo.duration} onChange={(v) => durationChange(v)} step="any" className="d-block text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white " placeholder="" required />
                            </div> */}

                            <div className="mb-6">
                                <label htmlFor="duration" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Duration (m:s)</label>
                                <input type="text" id="duration" defaultValue={selectedVideo.duration} onChange={(v) => selectedVideo.duration = v.target.value} step="any" className="d-block text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white " placeholder="" required />
                            </div>


                            <div className="mb-6">
                                <label htmlFor="type" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Availability</label>
                                <select id='type' defaultChecked={selectedVideo.type} defaultValue={selectedVideo.type} onChange={(v) => selectedVideo.type = v.target.value} className='mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white'>
                                    <option value={"paid"}>Paid</option>
                                    <option value={"free"}>Free</option>
                                </select>
                            </div>

                            <div className="mb-6">
                                <label htmlFor="price" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Is Active?</label>
                                <select id='is_active' defaultChecked={selectedVideo.status} defaultValue={selectedVideo.status} onChange={(v) => selectedVideo.status = v.target.value} className='mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white'>
                                    <option value={"coming_soon"}>Coming soon</option>
                                    <option value={"active"}>Active</option>
                                </select>
                            </div>

                            <div className="mb-6">
                                <label className='text-white block'>Upload video</label>
                                {/* <Dashboard
                                  height={350}
                                  uppy={uppyInstance}
                                  plugins={['DragDrop']}
                                />   */}
                                <label className='text-white'><input type={'file'} accept={'video/*'} onChange={(e) => uploadVideo(e)}/></label>
                                {uploadPercent > 0 && <div className="w-full bg-gray-200 rounded-full h-1.5 mb-4 dark:bg-gray-700">
                                  <div className="bg-blue-600 h-1.5 rounded-full dark:bg-blue-500" style={{width: `${uploadPercent}%`}}></div>
                                </div>}
                                <p className='text-white mt-1 mb-1'>- OR -</p>
                                <label htmlFor="video" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Set Video URL</label>
                                <input type="url" id="video" min={0} defaultValue={selectedVideo.video} onChange={(v) => videUrlChange(v)} step="any" className="d-block text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white " placeholder="" required />
                                {selectedVideo.video_path && <img className='mt-2' src={`${selectedVideo.video.replace(`playlist.m3u8`, `thumbnail.jpg`)}`} style={{maxWidth: 110}}/>}
                            </div>
                        </div>

                      </div>}   
                    </div>
                  </div>
                </div>
                <div className="bg-black px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6" style={{bottom: '0', position: 'fixed', width: '100%'}}>
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => saveVideo()}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={cancelEdit}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}