import React from "react";
import './courseStyle.css'

export default ({mobile = false}) => {
    //const lectures = [
    //    `Drawing Support & Resistance Levels`,
    //    `Indicators & the reason for not using them`,
    //    `Nature of Market & Price Travel`,
    //    `Gap up Gap Down Strategy `,
    //    `Fibonacci Setup `,
    //    `M & W | 3 Line Setup`,
    //    `Cup & Handle`,
    //    `Head & Shoulder`,
    //    `All Important Chart Patterns`,
    //    `Strike Price Selection`,
    //    `Putting the Stoploss`,
    //    `Trailing Stoploss`,
    //    `Trading in Trending Market`,
    //    `Trading in Sideways Market`,
    //    `Risk Management `,
    //    `Psychology & Discipline of a Trader`,
    //]

    //let newAdditions = [
    //    `Parallel Channel Strategy`,
    //    `⁠All Time High Levels`,
    //    `⁠Bigger Time frame as an Additional confirmation Strategy`,
    //    `⁠Types of Inside Candles`
    //]
    
    return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6" style={{color: 'white', minHeight: 200}}>
        
        {mobile && <link rel="stylesheet" type="text/css" href={'mobileapp.css'} />}
        <div className="mt-4">
            
                <h3 className="mt-4 pt-4">New Additions:</h3>
                <ul>
                    <li>• Parallel Channel Strategy</li>
                    <li>• All Time High Levels</li>
                    <li>• ⁠Bigger Time frame as an Additional confirmation Strategy</li>
                    <li>• Types of Inside Candles</li>
                </ul>

                <h3 className="mt-4 pt-4">What is Price Action?</h3>
                <ul>
                    <li>• Market Nature & Price Movement</li>
                    <li>• Indicators & my views for not using them</li>
                </ul>

                <h3 className="mt-4 pt-4">Support & Resistance Levels</h3>
                <ul>
                    <li>• Flat Market Levels</li>
                    <li>• Gap up/Gap Down Levels</li>
                    <li>• Strength of Levels</li>
                    <li>• ALL TIME HIGH LEVELS</li>
                </ul>

                <h3 className="mt-4 pt-4">Important Chart Patterns for Beginners </h3>
                <ul>
                    <li>• Fibonacci Setup</li>
                    <li>• M/W Setup | 3 Line Setup</li>
                    <li>• Cup & Handle Setup</li>
                    <li>• Head & Shoulder Setup</li>
                    <li>• Inside Candle Strategy</li>
                    <li>⁠• Parallel Channel Strategy</li>
                </ul>

                <h3 className="mt-4 pt-4">Suitable for Beginners</h3>
                <ul>
                    <li>• Strike Price Selection</li>
                    <li>• Putting the Stoploss</li>
                    <li>• Reducing the Stoploss using Risk Management</li>
                    <li>• Increasing the target using Trailing Stoploss</li>
                </ul>

                <h3 className="mt-4 py-4">Psychology & Discipline of a Trader</h3>
                <ul>
                    <li>• Roadmap to plan your quantities as a beginner </li>
                    <li>• One on One Support Guidelines</li>
                </ul>
 
        </div>
        <div className="max-w-4xl mx-auto mt-6">
            <hr />
            <div className="mx-auto my-4 pt-6">
            Once you learn chart reading, you can start to understand different charts like Nifty50, Nifty Bank, Equity stocks and basically anywhere where there is a chart and candle.
            <div className="my-4"></div>
            Always backtest first and then live test with the smallest capital possible to gain confidence and verify your own skills.
            </div>
            <div className="text-center">
                 {/* <a className="bg-theme-blue p-2 rounded-lg" href={"/live-class-details"+(mobile?'-app':'')}>&lt; Back</a> */}
                 
            </div>
        </div>
       
    </div>)
}