import React from "react";

const Testimonials = [
    {
        name: 'Kavish Sharma',
        say: 'I never thought understanding the chart could be this easy. All thanks to Prateek! Awesome guy.',
        image: 'https://cdn.pixabay.com/photo/2017/05/19/12/38/entrepreneur-2326419__340.jpg'
    },
    {
        name: 'Anmol Brar',
        say: 'This course gave me simple and effective strategies to understand the chart & now I can understand the chart better.',
        image: 'https://cdn.pixabay.com/photo/2017/05/19/12/38/entrepreneur-2326419__340.jpg'
    },
    {
        name: 'Pratham Joshi',
        say: 'Love the way sir speaks, love the way he explains. Prateek sir has literally changed my life.',
        image: 'https://cdn.pixabay.com/photo/2017/05/19/12/38/entrepreneur-2326419__340.jpg'
    }
]

export default () => {

    return (
        <div className="antialiased w-full h-full bg-black text-gray-400 font-inter p-10">
        <div className="container px-4 mx-auto">
        <div>
            <div id="title" className="text-center my-10">
            <h1 className="font-bold text-4xl text-white opacitiy-9">TESTIMONIALS</h1>
            <p className="text-light text-gray-400 text-xl mt-6">
                What our learners say?
            </p>
            </div>
            <div className="" style={{minHeight: 300}}>
                <div className="grid mt-6 lg:grid lg:grid-cols-3 lg:gap-x-6 gap-10 pt-10">
                    {Testimonials.map( (test, i) => <div className="p-4 border border-gray-800 _bg-gray-900 rounded-lg shadow-md" key={i} data-aos="fade-up"
     data-aos-anchor-placement="center-bottom" data-aos-duration="1500">
                        <div className="mb-2">
                            <p className="mb-2 text-center text-white ">
                                " {test.say} "
                            </p>
                            <div className="flex flex-col items-center justify-center">
                                {/* <div className="w-12 h-12 overflow-hidden bg-gray-100 border-2 border-indigo-100 rounded-full py-4 my-4">
                                    <img src={test.image} alt="img"
                                        className="object-cover object-center w-full h-full" />
                                </div> */}
                                <h5 className="font-bold text-gray-300 mt-2 text-2xl mt-4">{test.name}</h5>
                                {/* <p className="text-sm text-gray-600">CEO / Founder</p> */}
                            </div>
                        </div>
                    </div>)}
                </div>
            </div>
                
            </div>
        </div>
    </div>
    )
}