import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import EditVideo from './EditVideo'
import Course from '../models/Course'
import { getAuth } from "firebase/auth";
import firebaseApp from '../firebase'
import Compressor from 'compressorjs';

const auth = getAuth(firebaseApp);

export default function EditCourse({courses = [], index, editClosed = () => {}, saveCourse = () => {}}) {
  const [open, setOpen] = useState(true)

  const cancelButtonRef = useRef(null)
  const [allCourses] = useState(courses)
  const [selectedCourse, setSelectedCourse] = useState(null)

  const [base64Image, setBase64Image] = useState(null)
  const [fileName, setFileName] = useState(null)
  
  const imageFileInput = useRef(null)

  const [selectedVideoIndex, setSelectedVideoIndex] = useState(-1)

  useEffect(() => {
    setSelectedCourse(allCourses[index])
  }, [])

  useEffect(() => {
    if(open==false) {
        editClosed(selectedCourse)
    }
  }, [open])

  function onSaveCourse() {
    console.log(selectedCourse)
    // do async work here and then update local as well
    setOpen(false)
    saveCourse(selectedCourse)
  }

  function priceChange(syn) {
    let value = syn.target.value
    setSelectedCourse({...selectedCourse, price_value: parseFloat(value)*100, price: parseFloat(value).toFixed(2) +' /-' })
  }

  function validityChange(syn) {
    let value = syn.target.value
    let validityday = parseFloat(value)
    setSelectedCourse({
        ...selectedCourse, 
        validity_day: validityday, 
        validity: Course.validityText(validityday)  
    })
  }

  function addVideo() {
    console.log("HELLO")
    let newCourse = {...selectedCourse}
    newCourse.videos.push(Course.newVideo())

    setSelectedCourse(newCourse)
    setSelectedVideoIndex(newCourse.videos.length-1)
    
  }

  function updateVideo(video, removeIfCanceledAndNew) {
    console.log("UPDATE IT", video, removeIfCanceledAndNew)
    let newCourse = {...selectedCourse}
    
    if(removeIfCanceledAndNew){
        newCourse.videos.pop()
    } else {
        newCourse.videos[selectedVideoIndex] = video
    }
    setSelectedCourse(newCourse)
  }

  function imageSelected(v) {
    let file = v.target.files[0]
    if(file){
        let reader = new FileReader()

        reader.onloadend = function(eve){
           let result = eve.target.result.split(';base64')
           console.log(result.join(';'+file.name+';base64'))
           setBase64Image(result.join(';'+file.name+';base64'))
           setFileName(file.name)
        }

        reader.onerror = function(){
            alert("Image read failed!")
        }

        reader.readAsDataURL(file)
    } else {
        setBase64Image(null)
    }
  }

  function uploadNow() {
    auth.currentUser.getIdToken(true).then((token) => {
        return compressFile(imageFileInput.current.files[0], token)
    })
    .then((result) => {
        console.log(result)

        let formData = new FormData()
        let ftoken = result.token
        // formData.append('fileInput', base64Image)// imageFileInput.current.files[0]
        // formData.append('fileName', fileName)// imageFileInput.current.files[0]

        formData.append('fileInput', result.file) // imageFileInput.current.files[0] 
        formData.append('fileName', fileName)// imageFileInput.current.files[0]

        fetch('http://localhost:8999/uploadFile', {
            method: 'post',
            headers: {
                ftoken,
            },
            body: formData
        })
        .then(resp => {
            return resp.json()
        })
        .then(json => {
            setSelectedCourse({
                ...selectedCourse,
                image: json.url
            })
            clearImage()
           console.log(json)
        })
        .catch(err => {
            console.log(err)
        })
    
    })
    .catch(err => {
        console.log(err)
    })
  }

  function compressFile(file, token) {
    return new Promise((resolve, reject) => {
        new Compressor(file, {
            // quality: 0.8,
            maxHeight: 720,
            maxWidth: 1080,
            success(result) {
                resolve({file: convertBlobToFile(result), token})
            },
            error(err) {
                reject(err)
            },
        })
    })
  }

  function clearImage() {
    setBase64Image(null)
    imageFileInput.current.value = null
  }

  function convertBlobToFile(blobOrFile) {
    if (blobOrFile instanceof File){
        return blobOrFile
    }
    return new File([blobOrFile], fileName, {
        type: blobOrFile.type,
    })
  }

  function deleteVideo(video, vindex) {
    console.log(video, vindex)
    if(window.confirm(`Remove ${video.title} video?`)){
        let temp = {...selectedCourse}
        temp.videos.splice(vindex, 1)
        setSelectedCourse(temp)
    }
  }

  function showPreview(video) {
    if(video.video_path){
        let videoId = video.video_path.substring(1)
        window.open(`https://iframe.mediadelivery.net/play/${70980}/${videoId}`, '_blank')
    }
  }

  function activateVideo(_video, vindex) {
    let course = {...selectedCourse}
    console.log(course.videos[vindex])
    course.videos[vindex].status = course.videos[vindex].status=='active'?'coming_soon':'active'
    setSelectedCourse(course)
  }

  
  return (
    <div>
        <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen} >
            <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-black text-left shadow-xl transition-all sm:w-full ">
                {/*sm:my-8  sm:max-w-lg */}
                    <div className="bg-gray px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="flex sm:items-start pb-4 mb-4" style={{ maxHeight: 'calc(100vh - 40px)', overflowY: 'auto'}}>
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        {/* <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" /> */}
                        </div>
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left" style={{width: '100%'}}>
                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-white">
                            {selectedCourse && selectedCourse.is_new?'New':'Edit'} Course
                        </Dialog.Title>
                        {selectedCourse && <div className="mt-2 flex">
                            <div className='d-block w-full p-4'>
                                <div className="mb-6">
                                    <label htmlFor="title" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Course Image</label>
                                    <img src={base64Image || selectedCourse.image} style={{maxHeight: 200, maxWidth: 400}}/>
                                    {base64Image && <>
                                        <button onClick={uploadNow} className='inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 mt-1 px-2 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm mr-2'>Upload</button>
                                        <button onClick={clearImage} className='inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 mt-1 px-2 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm'>Clear</button>
                                        </>
                                        }
                                    <label className='ml-4 text-white inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-2 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm'>Select File<input style={{visibility: 'hidden', height: 0, width: 0}} type={'file'} ref={imageFileInput} accept="image/*" onChange={imageSelected} /></label>
                                </div>
                                <div className="mb-6">
                                    <label htmlFor="title" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Title</label>
                                    <input type="text" id="title" defaultValue={selectedCourse.title} onChange={(v) => selectedCourse.title = v.target.value} className="d-block text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white " placeholder="" required />
                                </div>

                                <div className="mb-6">
                                    <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Description</label>
                                    <input type="text" id="description" defaultValue={selectedCourse.short_description} onChange={(v) => selectedCourse.short_description = v.target.value} className="d-block text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white " placeholder="" required />
                                </div>

                                <div className="mb-6">
                                    <label htmlFor="price" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Price (INR)</label>
                                    <input type="number" id="price" min={0} defaultValue={selectedCourse.price_value/100} onChange={(v) => priceChange(v)} step="any" className="d-block text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white " placeholder="" required />
                                </div>

                                <div className="mb-6">
                                    <label htmlFor="validity" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Validity in Days</label>
                                    <input type="number" id="validity" min={0} defaultValue={selectedCourse.validity_day} onChange={(v) => validityChange(v)} step="any" className="d-block text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white " placeholder="" required />
                                </div>

                                <div className="mb-6">
                                    <label htmlFor="langugage" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Language</label>
                                    <input type="text" id="langugage" defaultValue={selectedCourse.language} step="any" className="d-block text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white " placeholder="" required />
                                </div>
                                <div className="mb-6">
                                    <label htmlFor="instructor" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Instructor</label>
                                    <input type="text" id="instructor" defaultValue={selectedCourse.instructor} step="any" className="d-block text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white " placeholder="" required />
                                </div>

                                <div className="mb-6">
                                    <label htmlFor="audience" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Audience</label>
                                    <select id='audience' defaultChecked={selectedCourse.audience} defaultValue={selectedCourse.audience} onChange={(v) => selectedCourse.audience = v.target.value} className='mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white'>
                                        <option value={"beginner"}>Beginner</option>
                                        <option value={"advance"}>Advance</option>
                                    </select>
                                </div>

                                <div className="mb-6">
                                    <label htmlFor="price" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Is Active?</label>
                                    <select id='is_active' defaultChecked={selectedCourse.status} defaultValue={selectedCourse.status} onChange={(v) => selectedCourse.status = v.target.value}  className='mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white'>
                                        <option value={"coming_soon"}>Coming soon</option>
                                        <option value={"active"}>Active</option>
                                    </select>
                                </div>
                            </div>
                            <div className='d-block w-full p-4'>
                                <div className="mb-6">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Videos</label>
                                    {selectedCourse.videos.map((video, vindex) => {
                                        return (
                                            <div className="text-white rounded-lg dark:bg-gray-800 mt-2 p-4 py-4 flex" key={video.title}>
                                                {/* <img className="h-14 w-20 rounded" src={course.image} alt="" /> */}
                                                <div className="flex-none w-80">
                                                    <h3>{video.title} <small className={video.status==='active'?"bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900":"bg-red-100 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-red-200 dark:text-red-900"}>{video.status || 'Coming Soon'}</small></h3>
                                                    <p style={{fontSize: 12, marginTop: 10}}><i>Duration: </i>{video.duration} | <i>Type:</i> {video.type=='paid'?'PAID':'FREE'}</p>
                                                    {/* <p><i>status:</i> {video.status}</p> */}
                                                    {/* <p >{JSON.stringify(video)}</p> */}
                                                    {/* <p><i>URL:</i> <small><code>{video.video}</code></small></p> */}
                                                </div>
                                                <div className="flex-none w-40 text-right">
                                                    <button onClick={() => { setSelectedVideoIndex(vindex);  }} className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-2 py-1 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm">Edit</button>
                                                    <button onClick={() => activateVideo(video, vindex)} className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-2 py-1 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm mt-1">Activate</button>
                                                    <button onClick={(e) => showPreview(video)} className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-2 py-1 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm mt-1">Preview</button>
                                                    <button  className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-2 py-1 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm mt-1" onClick={() => deleteVideo(video, vindex)}>Delete</button>
                                                </div>
                                            </div>
                                        )
                                    })}

                                </div>
                                <button className='inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm' onClick={() => { addVideo() }}>+ Add Video</button>
                                
                            </div>

                        </div>}   
                        </div>
                    </div>
                    </div>
                    <div className="bg-black px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6" style={{bottom: '0', position: 'fixed', width: '100%'}}>
                    <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => onSaveCourse()} >
                        Save
                    </button>
                    <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => editClosed(selectedCourse)}
                        ref={cancelButtonRef} >
                        Cancel
                    </button>
                    </div>
                </Dialog.Panel>
                </Transition.Child>
            </div>
            </div>
        </Dialog>
        </Transition.Root>

        <EditVideo videos={courses[index] ? courses[index].videos : []} index={selectedVideoIndex} editClosed={() => setSelectedVideoIndex(-1)} updateVideo={updateVideo}/>
    </div>
  )
}