import React, { useEffect } from "react";
import ClipboardJS from "clipboard";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import trading from './../assets/trading.jpeg'
import QRCode from './../assets/PV QR.jpg'

export default ({mobile = false }) => {
    // const UPIID = ['prateekvarshney1224@oksbi','prateekvarshney1224@okicici','prateekvarshney1224@okhdfcbank','prateekvarshney1224@okaxis']
    const UPIID = ['varshneyenterprise@icici']

    const lectures = [
        'Lecture 1 : FEAR OF LOSS!', 
        'Lecture 2 : Greed Management with Trailing STOPLOSS',
        'Lecture 3 : Controlling the Emotions while trading (Extremely Important)',
        'Lecture 4 : Working on your Confidence through logic and reasoning'
    ]
    var clipboard = null
    function clip(){
        if(clipboard){
           return  
        }
        clipboard = new ClipboardJS('.btn');

        clipboard.on('success', function(e) {
            console.info('Text:', e.text);
            // console.info('Action:', e.action);
            // console.info('Trigger:', e.trigger);
            toast( e.text + " Copied!")
            e.clearSelection();
        });

    }

    useEffect(() => {
        clip()
    })

    return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 py-6" style={{color: 'white', minHeight: 200}}>
         {mobile && <link rel="stylesheet" type="text/css" href={'mobileapp.css'} />}
         
        <h2 className="text-center text-4xl my-4">Trading Psychology</h2>
        <div>
            

            <div className="rounded-lg border border-gray-800 p-4">
                
                <iframe src="https://www.youtube.com/embed/sIfQ7vVJxac" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" style={{margin: '0 auto', padding: 20, maxWidth:"100%", width:"500px", height: "300px"}} allowfullscreen></iframe>

                <h2 className="mb-2 text-center">4 Interactive Lectures that you can watch as many times as needed</h2>
                
                <ol className="my-4">
                    {lectures.map((v, bi) => {
                        return (<li className="bg-gray-800 mt-4 p-4 rounded-lg text-white" key={bi}><b>{v}</b></li>)
                    })}
                </ol>
                
            </div>

            <h2 className="mb-2 mt-4 pt-4 text-center">PRICE: ₹ 349 /- only (validity 1 month)</h2>

            {/* <div className="rounded-lg bg-gray-900 p-4 mt-4">
                <p className="my-6 text-center">VIDEO SCREENSHOT</p>
                <img src={'/images/psycohology_course_screenshot.webp'} style={{maxWidth: 350, margin: '0 auto'}}/>
                <h2 className="mb-2">PRICE: ₹ 299 /- </h2>
                <p>
                    • ONE TIME FEE : ₹ 299 /-  <br />
                    • 11am or 9pm (You have to book one)
                </p>

                <h2 className="my-4">How to Pay?</h2>
                <div className="pb-4">
                    Pay ₹ 299 using any application like Googlepay, Paytm, Phonepay on following UPI ID
                    <ol>
                        {UPIID.map((v, bi) => {
                            return (<li className="bg-gray-800 mt-2 p-2" key={bi}><button className="bg-theme-blue float-right px-2 py-1 round btn" data-clipboard-text={v} onClick={() => {}}>COPY</button>{v}</li>)
                        })}
                    </ol>
                    <p className="my-6 py-4 text-center">Use following QR Code</p>
                    <img src={QRCode} style={{maxWidth: 200, margin: '0 auto'}}/>
                    <br />
                    No Refunds.
                    <br /> <br />
                    After Payment send the payment screenshot at <code><a href="https://t.me/optiontradingonlyadmin">@optiontradingonlyadmin</a></code>
                    <br />
                    
                    <p className="mt-2 text-center">If the enroll now button shows an error, directly search <b className="text-lg">'Optiontradingonlyadmin'</b> on Telegram</p>

                </div>
                
                   <ToastContainer />
            </div> */}
            <div className="flex justify-center  my-6 py-6">
                <a _href="https://t.me/optiontradingonlyadmin" href="https://bit.ly/3TeWGAG" target={"_blank"} className="text-center block font-medium text-xl p-4 rounded-xl hover:shadow-lg transition duration-200 ease-in-out bg-sky-500 text-white"
                style={{maxWidth: 400, fontWeight: "bold"}} >ENROLL NOW</a>
            </div>
        </div>
    
    </div>)
    
}

function Toast({text}){
    return (
        <div id="toast-default" className="flex items-center p-4 w-full max-w-xs text-gray-500 bg-green rounded-lg shadow" role="alert">
        <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-blue-500 bg-blue-100 rounded-lg">
            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z" clipRule="evenodd"></path></svg>
            <span className="sr-only">Fire icon</span>
        </div>
        <div className="ml-3 text-sm font-normal">Set yourself free.</div>
        <button type="button" className="ml-auto -mx-1.5 -my-1.5 bg-green text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8" data-dismiss-target="#toast-default" aria-label="Close">
            <span className="sr-only">Close</span>
            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
        </button>
    </div>
    )
}