
export default async () => {
   try {
    let resp = await fetch("https://api.optiontradingonly.in/date.json") 
    resp = await resp.json()
    return resp
   } catch (error) {
     console.log(error)   
     return null
   }
   
}