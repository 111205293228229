import React, { useEffect, useState } from "react";
import Course from "../models/Course";
import EditCourse from './AddOrEditCourse'

export default function CourseList({stagingCourseData}) {

    let [localCourseData, setLocalCourseData] = useState([])
    const [selectedCourseIndex, setSelectedCourseIndex] = useState(-1)

    useEffect(() => {
        setLocalCourseData(JSON.parse(JSON.stringify(stagingCourseData)))
    }, [stagingCourseData])

    function addCourse() {
        let length = stagingCourseData.length
        console.log(Course.newJSON() )
        setLocalCourseData( JSON.parse(JSON.stringify( stagingCourseData )).concat([ Course.newJSON() ]) )
        setSelectedCourseIndex(length)
    }

    function saveCourse(course) {
        let temp = [...localCourseData]
        temp[selectedCourseIndex] = course
        setLocalCourseData(temp)
        console.log(temp)
        // trigger save Firebase sync and update cloned staging data
        Course.saveV1CourseData(temp.map(v => { delete v.is_new; return v})).then((resp) => {
            alert("Course save success!")
        })
        .catch(error => {
            console.log(error)
            alert("Course save failed!")
        })
        //***** */ need to update parent staging alos
    }

    async function invokeEditCourse(index) {
        setLocalCourseData(JSON.parse(JSON.stringify(stagingCourseData)))
        setSelectedCourseIndex(index); 
    }

    function editClosed(newCourse) {
        console.log("CANCEL")
        if(newCourse){
           try {
                let temp = localCourseData
                if(newCourse.is_new){
                    temp.pop()
                }
                setLocalCourseData(temp)
           } catch (error) {
            console.log(error)
           }
        }
       
        setSelectedCourseIndex(-1);
    }

    function activate(index, bool) {
        let temp = localCourseData.map((v, vindex) => {
            if(vindex == index){
                v.status = bool==true ? "active" : "coming_soon"
            }
            return v
        })

        Course.saveStagingCourseData(temp).then((resp) => {
            alert("Course activated success!")
        })
        .catch(error => {
            console.log(error)
            alert("Course save failed!")
        })
    }

    return (
        <div>
            <h2 className="text-white" style={{marging: '20px 0'}}>Manage Course</h2>

            <table className="" style={{width: '100%'}}>
                <tbody>
                    {localCourseData.map((course, index) => { return (<CourseTrElement key={index} course={course} index={index} invokeEditCourse={invokeEditCourse} activate={activate}/>) })}
                    {localCourseData.length<1?<tr><td colSpan={3} className="text-center text-white p-4 bg-gray-700">No course data found!</td></tr>:<></>}
                </tbody>
            </table>
            
            <button className="rounded-lg dark:bg-blue-800 mt-4 p-4 text-white" onClick={() => addCourse()}>+ Add Course</button>

            {selectedCourseIndex >- 1 && <EditCourse courses={localCourseData} index={selectedCourseIndex} editClosed={editClosed} saveCourse={saveCourse}/>}
        </div>
    )
}

function CourseTrElement({course, index, invokeEditCourse, activate}) {
    return (
        <tr className="text-white rounded-lg border-slate-50 dark:bg-gray-800 p-4" key={course.course_id}>
            <td style={{width: '200px'}}><img className="rounded" src={course.image} alt="" style={{ margin: '0 auto', width: 140}}/></td>
            <td><div className="p-4">
                <h3>{course.title} <small className={course.status==='active'?"bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900":"bg-red-100 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-red-200 dark:text-red-900"}>{course.status}</small>
                <small className={course.published?"bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900":"bg-red-100 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-red-200 dark:text-red-900"}>{course.published?"Published":"Not published"}</small>
                </h3>
                <p><i>Short Description: </i>{course.short_description}</p>
                <p><i>Price:</i> {course.price}</p>
            </div>
            </td>
            <td><div className="">
                <button onClick={() => { invokeEditCourse(index) }} className="p-4">Edit</button>
                {course.status==='active'?<button className="p-4" onClick={() => activate(index, false)}>Deactivate</button>:<button className="p-4" onClick={() => activate(index, true)}>Activate</button>}
            </div>
            </td>
        </tr>
    )
}