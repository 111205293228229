import React, { useEffect, useState } from "react";
import ClipboardJS from "clipboard";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import QRCode from './../assets/PV QR.jpg'
import 'lite-youtube-embed'
import 'lite-youtube-embed/src/lite-yt-embed.css'
import './live-class.css'
import CourseDetails from "./CourseDetails";
import WEEKLYIMG from './../assets/weeklyperformance.jpeg'

const syllabus = [
    ['Nature of Marke', 'Setups & Rules', 'Psychology & Risk Management'],
    ['Price Behaviour', 'Support & Resistance', '40-60 Rules'],
    ['Chart Reading', 'Chart Patterns', 'Level Profit Booking'],
    ['Position Planning', 'When to Avoid<br/>When to Enter', 'Strike Price for best return'],
    ['Common Sense<br/>&<br/>Logical Approach', 'Trailing Stoploss', 'Stoploss'],
    ['-', '-', 'Psychological aspects of trading']
]

const lectures = [
    `Drawing Support & Resistance Levels`,
    `Indicators & the reason for not using them`,
    `Nature of Market & Price Travel`,
    `Gap up Gap Down Strategy `,
    `Fibonacci Setup `,
    `M & W | 3 Line Setup`,
    `Cup & Handle`,
    `Head & Shoulder`,
    `All Important Chart Patterns`,
    `Strike Price Selection`,
    `Putting the Stoploss`,
    `Trailing Stoploss`,
    `Trading in Trending Market`,
    `Trading in Sideways Market`,
    `Risk Management `,
    `Psychology & Discipline of a Trader`,
]

export default () => {
    // const UPIID = ['prateekvarshney1224@oksbi','prateekvarshney1224@okicici','prateekvarshney1224@okhdfcbank','prateekvarshney1224@okaxis']
    const [dates, setDates] = useState([new Date(), new Date()])
    const UPIID = ['varshneyenterprise@icici']

    const [showSyllabus, setShowSyllabus] = useState(false)
    const [showOneImage, setShowOneImage] = useState(false)
    const [showTiming, setShowTiming] = useState(false)
    
    
    var clipboard = null
    function clip(){
        if(clipboard){
           return  
        }
        clipboard = new ClipboardJS('.btn');

        clipboard.on('success', function(e) {
            console.info('Text:', e.text);
            // console.info('Action:', e.action);
            // console.info('Trigger:', e.trigger);
            toast( e.text + " Copied!")
            e.clearSelection();
        });

    }

    function getNewDate() {
        let today = new Date()
        // if(today.getMonth() <= 2-1 || (today.getMonth() <= 3-1 && today.getDate() <= 14)){
        //     return new Date(2023,3,14)
        // }
        return today
    }
    
    useEffect(() => {
        let today = getNewDate()// 
        console.log(today, today.getMonth())
        // if(today.getMonth() <= 3 && today.getMonth() <= 4){
        //     setDates([8,9,12,13,16,23].map(v => new Date(2024,3,v)))
        // } else 
        if(today.getMonth() > 3 && today.getMonth() < 4){
            console.log("WHY HERE")
            setDates([6,7,10,11,14,21].map(v => new Date(2024,4,v)))
        } else if(today.getMonth() >= 4 && today.getMonth() < 5){
            console.log("SS", [10,11,14,15,18,25].map(v => new Date(2024,5,v)))
            setDates([10,11,14,15,18,25].map(v => new Date(2024,5,v)))
        } else if(today.getMonth() > 5 && today.getMonth() <= 6){
            console.log("SSSSS")
            setDates([8,9,12,13,16,23].map(v => new Date(2024,6,v)))
        } else {
            setDates([])
        }

    }, [])

    useEffect(() => {
        clip()
    })

    const nth = function(d) {
        if (d > 3 && d < 21) return 'th';
        switch (d % 10) {
          case 1:  return "st";
          case 2:  return "nd";
          case 3:  return "rd";
          default: return "th";
        }
      }

    function format(date){
        let dateStr = date.toDateString()
        const dateStrArr = dateStr.split(' ')
        
        return [date.getDate().toString()+nth(date.getDate()), dateStrArr[1]].join('-')
    }

    return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 py-6" style={{color: 'white', minHeight: 200}}>
        <h2 className="text-center text-4xl my-4">ADVANCE COURSE - Option Trading Only</h2>
            <div className="rounded-lg border border-gray-800 p-4">
            {/* https://youtu.be/tkZncX3A8C0 */}
                <lite-youtube videoid="tkZncX3A8C0" width="460" height="300" style={{maxWidth: '100%', width: "400px", height: 300, margin: '0 auto'}} playlabel="Play: Demo Class"></lite-youtube>

                <h2 className="mb-2 text-center">Taking you to the next level with advance strategies</h2>
                

                <div className="rounded-lg _bg-gray-900 p-4 mt-4">
                    <div className="rounded-lg bg-gray-800 mt-4 p-4 mb-4">
                    <b>Syllabus of Advance course </b><button className="bg-theme-blue2 px-2 -mt-1 float-right rounded-md" onClick={() => setShowSyllabus(!showSyllabus)} _href={"/course-details?a="+Date.now()}><span className="md:block hidden">{showSyllabus?'HIDE':'SHOW'}</span><span className="md:hidden block -mt-1"><i className={`indicator glyphicon glyphicon-chevron-${showSyllabus?'down':'up'}-custom pull-right`}><span className="sp-1"></span><span className="sp-2"></span></i></span></button>
                    </div>

                    {showSyllabus && <AdvanceCourseSyllabus />}

                    <div className="rounded-lg bg-gray-800 mt-4 p-4"><b>Class Schedule</b>
                        <button className="bg-theme-blue2 px-2 -mt-1 float-right rounded-md" onClick={() => setShowTiming(!showTiming)} _href={"/course-details?a="+Date.now()}><span className="md:block hidden">{showTiming?'HIDE':'SHOW'}</span><span className="md:hidden block -mt-1"><i className={`indicator glyphicon glyphicon-chevron-${showTiming?'down':'up'}-custom pull-right`}><span className="sp-1"></span><span className="sp-2"></span></i></span></button>
                    </div>
                    {showTiming && <div className="mt-4 pl-4">
                        <p>
                            {/* {`Class-1: 8th April at 9pm | Saturday (Duration: 1.5 Hours)
                            Class-2: 9th April at 9pm | Sunday (Duration: 1.5 Hours)
                            Class-3: 12th April at 12pm (noon) in Live Market on Wednesday (Duration: 30-45 mins)
                            Class-4: 13th April at 12pm (noon) in Live Market on Expiry Day (Duration: 30-45 mins)
                            Class-5: 16th April 6am-10am | One on One Support on Telegram
                            Class-6: 23rd April 6am-10am | One on One Support on Telegram`.split('Class-').filter((v,i) => i>0).map(v => <> Class-{v} <br/><br/></>)} */}
                             {`Class-1: 10th June at 9pm | Saturday (Duration: 1.5 Hours)
                            Class-2: 11th June at 9pm | Sunday (Duration: 1.5 Hours)
                            Class-3: 14th June at 12pm (noon) in Live Market on Wednesday (Duration: 30-45 mins)
                            Class-4: 15th June at 12pm (noon) in Live Market on Expiry Day (Duration: 30-45 mins)
                            Class-5: 18th June 6am-10am | One on One Support on Telegram
                            Class-6: 25th June 6am-10am | One on One Support on Telegram`.split('Class-').filter((v,i) => i>0).map(v => <> Class-{v} <br/><br/></>)}
                        </p>
                    </div>}
                </div>
        
            <div className="rounded-lg _bg-gray-900 py-4 mt-4">
                <h2 className="mb-2">PRICE</h2>
                <p className="mb-4">
                    • ₹ 21,000 /-  <br />
                </p>

                <div className="mt-4">
                    <div style={{textAlign: 'center', margin:'20px 0 40px'}}>
                        <a href="https://rzp.io/l/optiontradingonlycourse" target={"_blank"} className="bg-theme-blue2 p-4 my-4 rounded-md">PAY NOW</a>
                    </div>
                    <p className="mt-4 mb-4">
                        <a href="/refund-policy"><u>Refund Policy</u></a>
                    </p>
                    After Payment, send the payment screenshot and these details 
                    <br /> <br />
                    Full Name<br />
                    Mobile Number<br />
                    Email ID<br /><br />

                    on Telegram <code><a aria-label="Telegram" href="https://t.me/optiontradingonlyadmin">@optiontradingonlyadmin</a></code>
                    <br/>
                    <br/>
                    <i>
                        <b>If the Telegram link gives you an error, then mail the screenshot at <a href="mailto:optiontradingonly@gmail.com">optiontradingonly@gmail.com</a></b>
                    </i>
                </div>
                   <ToastContainer />
            </div>
        </div>
    
    </div>)
    
}

function AdvanceCourseSyllabus(){

    return <div className="pl-4">
        ✓ Chindi Chori Scalping Strategy <br/><br/>
        
        ✓ V-shape Recovery Setup<br/><br/>

        ✓ Advance Triangle Breakout Strategy<br/><br/>
      
        ✓ Parallel Channel Strategy<br/><br/>

        ✓ Swing Trend Theory (N-Setup)<br/><br/>
        
        ✓ Inside Candle with better win rate<br/><br/>
        
        ✓ Identifying the Market Trap before Stoploss<br/><br/>
       
        ✓ Dynamic Support/Resistance Levels<br/><br/>

        ✓ All time high Support & Resistance Levels<br/><br/>

        ✓ 10 Second Rule for Entries<br/><br/>

        ✓ How to plan your no. of trades per day?<br/><br/>

        ✓ How to play with different strike prices? <br/><br/>
        
        ✓ How to use both Indexes in your Favour? <br/><br/>
        
        ✓ Different methods to take stoploss <br/><br/>

        and more.<br/><br/>

        <br/>

        <b>BONUS:</b> One Option Selling Strategy<br/><br/>
    </div>
}