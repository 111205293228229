import firebaseApp from '../firebase'
import { getAuth } from "firebase/auth";
const auth = getAuth(firebaseApp);

class FBNotification{
    static async sendNotification(payload = {}){
        const token = await auth.currentUser.getIdToken(true)
        // console.log( " " + token )
        return fetch(`${process.env.REACT_APP_ADMIN_URL}/admin/send-notification`, {
            method: 'POST',
            headers: {
                ftoken: token,
                'Content-Type': 'application/json' 
            },
            body: JSON.stringify(payload)
        })
    }
}

export default FBNotification