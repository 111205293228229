export default [
  {
    "title": "Relation b/w Nifty & Bank Nifty",
    "duration": "6:51",
    "id": "eOOfR0k2kR8"
  },
  {
    "title": "Choosing NFTs, Crypto OVER Indian STOCK MARKET?",
    "duration": "6:46",
    "id": "byrvx6odbu0"
  },
  {
    "title": "Fibonacci Retracement | Option Trading Only by Prateek Varshney",
    "duration": "8:07",
    "id": "mAdi5UdoWuA"
  },
  {
    "title": "Capture the Premium Spike | THE CHINDI CHORI SETUP by Prateek Varshney",
    "duration": "5:37",
    "id": "U6JuFvOgp1s"
  },
  {
    "title": "Laptop for TRADING | Which one?",
    "duration": "3:54",
    "id": "c1UfQMETUiQ"
  },
  {
    "title": "Watching Dow Jones for Nifty / Bank Nifty?",
    "duration": "10:33",
    "id": "X_41nU89i0A"
  },
  {
    "title": "Why Bookish knowledge NEVER works in Stock market? | Find your EDGE!",
    "duration": "8:38",
    "id": "ZJAqY_mKpmc"
  },
  {
    "title": "Option Trading as a CAREER",
    "duration": "10:57",
    "id": "NKfZ3l_bAck"
  },
  {
    "title": "LIVE TRADING with 1 lot | Hedging - Margin Benefit",
    "duration": "4:11",
    "id": "XfkuOG8MHhU"
  },
  {
    "title": "Job & Trading Together? Here is How",
    "duration": "5:15",
    "id": "2LQpo54wqi0"
  },
  {
    "title": "Option Buying vs Option Selling",
    "duration": "10:23",
    "id": "XBOfNByTF00"
  },
  {
    "title": "What to Expect from 1 LOT in Options Intraday?",
    "duration": "4:54",
    "id": "atqo4tiUKnU"
  },
  {
    "title": "Risk Reward Ratio in Option Trading",
    "duration": "8:03",
    "id": "HRjxAxZa2xA"
  },
  {
    "title": "Changing the Trading Scene ONCE AND FOR ALL",
    "duration": "10:01",
    "id": "3_khQNWpZ68"
  },
  {
    "title": "BEWARE OF FAKE/ FRAUD PAGES USING SIMILAR NAMES",
    "duration": "2:45",
    "id": "Ca3CnYQPLBk"
  },
  {
    "title": "Why/How do I keep my Trading so SIMPLE?",
    "duration": "5:28",
    "id": "BF1fmBK05yw"
  },
  {
    "title": "Why I prefer Nifty50 over Bank Nifty?",
    "duration": "1:52",
    "id": "M6Z-nV8O0io"
  },
  {
    "title": "Option Trading Only by Prateek Varshney | Demo Class | Price Action - Option Buying Course in India",
    "duration": "7:39",
    "id": "onVh9dQ9YGU"
  },
  {
    "title": "P&L Option Trading Only | Prateek Varshney",
    "duration": "2:07",
    "id": "r0t3pms51AU"
  },
  {
    "title": "What is Option Trading? #stockmarket",
    "duration": "10:22",
    "id": "bWBIYoQUhD4"
  },
  {
    "title": "How to put Stoploss in Options? #stockmarket",
    "duration": "3:00",
    "id": "VD3LR5qAoSk"
  }
]

// {
//   "title": "Relation b/w Nifty & Bank Nifty",
//   "duration": "6:51",
//   "id": "eOOfR0k2kR8"
// },
// {
//   "title": "Choosing NFTs, Crypto OVER Indian STOCK MARKET?",
//   "duration": "6:46",
//   "id": "byrvx6odbu0"
// },
// {
//   "title": "Fibonacci Retracement | Option Trading Only by Prateek Varshney",
//   "duration": "8:07",
//   "id": "mAdi5UdoWuA"
// },
// {
//   "title": "Capture the Premium Spike | THE CHINDI CHORI SETUP by Prateek Varshney",
//   "duration": "5:37",
//   "id": "U6JuFvOgp1s"
// },
// {
//   "title": "Laptop for TRADING | Which one?",
//   "duration": "3:54",
//   "id": "c1UfQMETUiQ"
// },
// {
//   "title": "Watching Dow Jones for Nifty / Bank Nifty?",
//   "duration": "10:33",
//   "id": "X_41nU89i0A"
// },
// {
//   "title": "Why Bookish knowledge NEVER works in Stock market? | Find your EDGE!",
//   "duration": "8:38",
//   "id": "ZJAqY_mKpmc"
// },
// {
//   "title": "Option Trading as a CAREER",
//   "duration": "10:57",
//   "id": "NKfZ3l_bAck"
// },
// {
//   "title": "LIVE TRADING with 1 lot | Hedging - Margin Benefit",
//   "duration": "4:11",
//   "id": "XfkuOG8MHhU"
// },
// {
//   "title": "Job & Trading Together? Here is How",
//   "duration": "5:15",
//   "id": "2LQpo54wqi0"
// },
// {
//   "title": "Option Buying vs Option Selling",
//   "duration": "10:23",
//   "id": "XBOfNByTF00"
// },
// {
//   "title": "What to Expect from 1 LOT in Options Intraday?",
//   "duration": "4:54",
//   "id": "atqo4tiUKnU"
// },
// {
//   "title": "Risk Reward Ratio in Option Trading",
//   "duration": "8:03",
//   "id": "HRjxAxZa2xA"
// },
// {
//   "title": "Changing the Trading Scene ONCE AND FOR ALL",
//   "duration": "10:01",
//   "id": "3_khQNWpZ68"
// },
// {
//   "title": "BEWARE OF FAKE/ FRAUD PAGES USING SIMILAR NAMES",
//   "duration": "2:45",
//   "id": "Ca3CnYQPLBk"
// },
// {
//   "title": "Why/How do I keep my Trading so SIMPLE?",
//   "duration": "5:28",
//   "id": "BF1fmBK05yw"
// },
// {
//   "title": "Why I prefer Nifty50 over Bank Nifty?",
//   "duration": "1:52",
//   "id": "M6Z-nV8O0io"
// },
// {
//   "title": "Option Trading Only by Prateek Varshney | Demo Class | Price Action - Option Buying Course in India",
//   "duration": "7:39",
//   "id": "onVh9dQ9YGU"
// },
// {
//   "title": "P&L Option Trading Only | Prateek Varshney",
//   "duration": "2:07",
//   "id": "r0t3pms51AU"
// },
// {
//   "title": "What is Option Trading? #stockmarket",
//   "duration": "10:22",
//   "id": "bWBIYoQUhD4"
// },
// {
//   "title": "How to put Stoploss in Options? #stockmarket",
//   "duration": "3:00",
//   "id": "VD3LR5qAoSk"
// }