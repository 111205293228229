import moment from "moment/moment"


const getNewDate = () => {
    let today = new Date()
    //today = new Date(2023, 6, 4)
    //if(today.getMonth() <= 8 || (today.getMonth() <= 9 && today.getDate() <= 1)){
    //    // console.log('ss', Date(2023,8,1))
    //     return new Date(2023,9,2)
    // }
    //|| (today.getMonth() <= 1 && today.getDate() <= 7)
    console.log( today.getMonth() , today.getDate())
    
    if( moment().isBefore(moment(new Date(2024,5,4))) ){
        return new Date(2024,5,4)
    }

    return today
}

export default getNewDate

export const normalDateSetup = () => {
    let today = getNewDate()// 
    
    let sunday = null, saturday = null
    
    console.log(today.getFullYear(), today.getMonth(), today, today.getDay())
    if(today.getDay()==6){
        console.log("Its saturday")
        // its saturday
        // console.log(today.getHours())

        if(today.getHours() >= 16){
            // show next date
            sunday = getNewDate()
            sunday.setDate(getNewDate().getDate()+8)
            saturday = getNewDate()
            saturday.setDate(getNewDate().getDate()+7)
        } else {
            // show this weeks date
            sunday = getNewDate()
            sunday.setDate(getNewDate().getDate()+1)
            saturday = getNewDate()
        }
        
    }
    else if(today.getDay()==0){
        // its sunday
        sunday = getNewDate()
        sunday.setDate(getNewDate().getDate()+7)
        saturday = getNewDate()
        saturday.setDate(getNewDate().getDate()+6)
    } else {
        sunday = getNewDate()
        sunday.setDate(getNewDate().getDate() + (7 - getNewDate().getDay()))

        saturday = getNewDate()
        saturday.setDate(getNewDate().getDate() + (6 - getNewDate().getDay()) )

    }
    return [saturday, sunday]
}