import React from "react";
import Carousel from './Carousel'
import Courses from './Courses';
import WhyUs from './WhyUs';
import Testimony from './Testimony';
import Features from './Features';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTelegram} from '@fortawesome/free-brands-svg-icons'

export default ({mobile = false}) => {
    return (
      <>
        {mobile && <link rel="stylesheet" type="text/css" href={'mobileapp.css'} />}
        
        <div className="content pb-2">
          <Carousel mobile={mobile}/>
          <Features mobile={mobile}/>
          
          {!mobile &&<><Courses></Courses>
           <WhyUs />
          <Testimony /></>}
        </div>
      </>
    )
  }