const ImgWithFallback = ({
    src,
    fallback,
    type = 'image/webp',
    ...delegated
  }) => {
    return (
      <picture>
        <source srcSet={src} type={type} />
        <img src={fallback} {...delegated} loading="lazy-load" alt="Image"/>
      </picture>
    )
  }

export default ImgWithFallback