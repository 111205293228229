import React from "react";
import { ThumbUpIcon, StarIcon, UsersIcon, PresentationChartLineIcon } from '@heroicons/react/solid'
import 'lite-youtube-embed'
import 'lite-youtube-embed/src/lite-yt-embed.css'

const stats = (mobile) =>  [
    {
        link: 'https://www.youtube.com/c/OptionTradingOnly',
        title: 'YOUTUBE SUBSCRIBERS',
        count: '1Lakh+',
        icon: <ThumbUpIcon className="h-5 w-5 text-sky-500 opacity-50"/>
    },
    {
        link: 'http://t.me/optiontradingoriginal',
        title: 'TELEGRAM MEMBERS',
        count: '46K+',
        icon: <StarIcon className="h-5 w-5 text-sky-500 opacity-50"/>
    },
    {
        link: 'https://www.instagram.com/optiontradingonly/',
        title: 'INSTAGRAM FOLLOWERS',
        count: '45K+',
        icon: <PresentationChartLineIcon className="h-5 w-5 text-sky-500 opacity-50"/>
    },
    {
        link: mobile?'/live-class-details-app':'/live-class-details',
        title: 'STUDENTS MENTORED',
        count: '10K+',
        icon: <UsersIcon className="h-5 w-5 text-sky-500 opacity-50"/>
    }
]

export {stats}

export default ({mobile = false}) => {
    
    return (
        <div id="wju" className="antialiased w-full h-full bg-black text-gray-400 font-inter p-10">
            <div className="container px-4 mx-auto">
                <div>
                <div id="title" className="text-center my-10">
                <h1 className="font-bold text-4xl text-white">WHY JOIN US?</h1>
                
                </div>
                <div className="flex justify-center">
                    {/* <iframe style={{maxWidth: '100%'}} width="560" height="315" src="https://www.youtube.com/embed/3_khQNWpZ68?controls=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
                    <lite-youtube videoid="3_khQNWpZ68" style={{maxWidth: '100%', width: 560}} playlabel="Why Join Us"></lite-youtube>
                </div>
                {/* <lite-youtube videoid="3_khQNWpZ68" width="560" height="315" style={{maxWidth: '100%'}} playlabel="Why Join Us"></lite-youtube> */}
                <p className="text-light text-center text-gray-400 text-xl mt-6 py-4">
                    The number speaks for itself.
                </p>
                <StatHolder stats={stats(mobile)}/>
                
            </div>
            </div>
        </div>
    )
}


function StatHolder({stats}) {
    return (
        <div className="flex items-center">
            <div className="mx-auto container align-middle" style={{minHeight: 200}}>
                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-4 justify-evenly gap-10 pt-10">
                    {stats.map((stat, i) => <StatItem stat={stat} key={i}/>)}
                </div>
            </div>
        </div>
    )
}

function StatItem({stat}){
    return (
        <a href={stat.link} target={"_blank"} className="shadow rounded-lg py-5 px-5 bg-gray-900 " data-aos="flip-down"  data-aos-duration="2000">
            <div className="flex flex-row justify-between items-center">
                <div>
                {/* <h6 className="text-2xl">{stat.title}</h6> */}
                    <h4 className="text-white text-2xl font-bold text-left mb-3">{stat.count}</h4>
                </div>
                <div>
                    {stat.icon}
                </div>
            </div>
            <div className="text-left flex flex-row justify-start items-center">
                <span className="mr-1">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="#14B8A6"
                    strokeWidth="2">
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
                        />
                </svg>
                </span>
                <p>{stat.title}</p>
            </div>
        </a>
    )
}