// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBV9_JBwDJxtNqtdA3l3327zhl5_ibs8Ro",
  authDomain: "thinklms-43300.firebaseapp.com",
  projectId: "thinklms-43300",
  storageBucket: "thinklms-43300.appspot.com",
  messagingSenderId: "657504731236",
  appId: "1:657504731236:web:91f4f2034acfb90fe69f71",
  measurementId: "G-WQCBG2FE2Z"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default app 
export {analytics}